import React, { useEffect, useState } from 'react'
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { formatDiscount } from '../../utils/helper';
import './discountForm.scss';

const DiscountForm = ({ open, confirm, cancel }) => {

    const [name, setName] = useState('')
    const [type, setType] = useState('percent')
    const [value, setValue] = useState('')
    const [allDiscounts, setAllDiscounts] = useState([])

    useEffect(() => {
        fetchAllDiscounts()
    }, [])

    const handleSubmit = () => {
        console.log('data', allDiscounts);
        confirm(allDiscounts);
    }

    const handleCancel = () => {
        cancel();
    }

    const fetchAllDiscounts = () => {
        // api to fetch all globally applied discounts
        const dummyAllDiscounts = [
            { name: 'abc', type: 'percent', value: '10' },
            { name: 'xyz', type: 'price', value: '100' }
        ]
        setAllDiscounts(dummyAllDiscounts)
    }

    const handleAdd = () => {
        if (name === '' || value === '') {
            return alert('please fill all fields')
        }
        let allDiscountsRef = allDiscounts
        let data = {
            name,
            type,
            value
        }
        allDiscountsRef.push(data)
        setAllDiscounts(allDiscountsRef)
        clearForm();
    }

    const clearForm = () => {
        setName('')
        setType('percent')
        setValue('')
    }

    const handleRemoveDiscount = (index) => {
        setAllDiscounts(allDiscounts.filter((item, i) => i !== index))
        clearForm();
    }
    return (
        <div>
            <Modal isOpen={open} toggle={() => cancel()} >
                <ModalHeader toggle={() => cancel()}>Global Discounts</ModalHeader>
                <ModalBody>
                    <b>Applied Discounts</b>
                    {allDiscounts.map((item, index) => <div key={index} className="discountRow">
                        {formatDiscount(item)}
                        <i title={'remove this discount from all Vehicles'} className='fa fa-remove' onClick={() => handleRemoveDiscount(index)} />
                    </div>)}
                    <div className="form-group mt-20">
                        <b>Add New Offer</b><br/>
                        <label>Name</label>
                        <input className="form-control" name='modelName' onChange={(e) => setName(e.target.value)} value={name} />
                        <label>Type</label>
                        <select className="form-control" onChange={(e) => setType(e.target.value)}>
                            <option value="percent">Percent(%)</option>
                            <option value="price">Price(₹)</option>
                        </select>
                        <label>Value</label>
                        <input type='number' className="form-control" name='modelName' onChange={(e) => setValue(e.target.value)} value={value} />
                    </div>
                    <button className='button-add' onClick={() => handleAdd()}>Add</button>
                </ModalBody>
                <ModalFooter>
                    <Button color="primary" onClick={() => handleSubmit()}>Save</Button>{' '}
                    <Button color="secondary" onClick={() => handleCancel()}>Cancel</Button>
                </ModalFooter>
            </Modal>
        </div>
    );
}

export default DiscountForm;