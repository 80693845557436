import React from 'react'
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';

const DisplayBookingDetailsModal = ({ open, handleCancel, data: { carData, userBookingData } }) => {
    return (
        <>
            <Modal isOpen={open} toggle={() => handleCancel()} >
                <ModalHeader toggle={() => handleCancel()}>Booking Details</ModalHeader>
                <ModalBody>
                    <div className="row">
                        <div className="col-4">Name :</div>
                        <div className="col-auto">{userBookingData.name}</div>
                    </div>
                    <div className="row">
                        <div className="col-4">Pickup Location :</div>
                        <div className="col-auto">{userBookingData.pickup}</div>
                    </div>
                    <div className="row">
                        <div className="col-4">Drop Location :</div>
                        <div className="col-auto">{userBookingData.drop}</div>
                    </div>
                    <div className="row">
                        <div className="col-4">Contact No. :</div>
                        <div className="col-auto">{userBookingData.mobile}</div>
                    </div>
                    <div className="row">
                        <div className="col-4">Email :</div>
                        <div className="col-auto">{userBookingData.email}</div>
                    </div>
                    <div className="row">
                        <div className="col-4">Start Date :</div>
                        <div className="col-auto">{userBookingData.startDate?.substring(0, 10)}</div>
                    </div>
                    <div className="row">
                        <div className="col-4">End Date :</div>
                        <div className="col-auto">{userBookingData.endDate?.substring(0, 10)}</div>
                    </div>
                    <div className="row">
                        <div className="col-4">Kilometers :</div>
                        <div className="col-auto">{userBookingData.kms}</div>
                    </div>
                    <div className="row">
                        <div className="col-4">Hours :</div>
                        <div className="col-auto">{userBookingData.hrs}</div>
                    </div>
                    <div className="row">
                        <div className="col-4">Total Amount :</div>
                        <div className="col-auto">{userBookingData.amount}</div>
                    </div>
                    <br />
                    <strong>Vehicle Details</strong>
                    <div className="row">
                        <div className="col-4">Name :</div>
                        <div className="col-auto">{carData.name}</div>
                    </div>
                    <div className="row">
                        <div className="col-4">Fuel Type:</div>
                        <div className="col-auto">{carData.fuelType}</div>
                    </div>
                    <div className="row">
                        <div className="col-4">Seater :</div>
                        <div className="col-auto">{carData.seater}</div>
                    </div>

                </ModalBody>
                <ModalFooter>
                    <Button color="primary" onClick={() => handleCancel()}>Close</Button>
                </ModalFooter>
            </Modal>
        </>
    )
}

export default DisplayBookingDetailsModal