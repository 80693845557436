import React from 'react'
import { tourAndTravelPolicies } from '../../utilsData/tourAndTravelPolicies'
import Header from '../header'
import './tnc.scss'

const TourAndTravelPolicies = () => {
    return (
        <div>
            <Header />
            <div className='container'>
                {tourAndTravelPolicies}
            </div>
        </div>
    )
}

export default TourAndTravelPolicies
