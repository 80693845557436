import React, { useState, useEffect } from 'react'
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { useCookies } from "react-cookie";
import { useNavigate } from 'react-router';
import axios from 'axios';

import UploadDoc from '../uploadDocument/uploadDoc';


const BookingDetailsContainer = ({ open, selectedCarData, bookingDetails, handleCancel }) => {

    const [cookies, setCookie] = useCookies()
    const [userLoggedIn, setUserLoggedIn] = useState(false);

    const [openUploadTab, setUpload] = useState(false);

    const [userData, setUserData] = useState({});
    const [buttonDisable , setButton] = useState(false);


    
    useEffect(() => {
        if (cookies && cookies.authorization) {
            setUserLoggedIn(true);

        }
    
    }, []);

    useEffect(()=>{
        setUpload(false)
    },[open])

    const navigate = useNavigate();

    const handlePayNow = () => {
        setCookie('bookingDetails', getBokingObj());


        if (userLoggedIn) {
            const data = {
                "order_id": Date.now().toString(),
                "order_amount": parseFloat(selectedCarData.price),
                "order_currency": "INR",
                "customer_details": {
                    "customer_id": bookingDetails.name.split(' ').join(''),
                    "customer_email": bookingDetails.mail,
                    "customer_phone": bookingDetails.mobileNo,
                    "customer_name": bookingDetails.name,
                },
                "order_meta": {
                    "return_url": `https://thestandardjourney.com/payment_status?order_id={order_id}&order_token={order_token}`
                }
            }


            const orderUrl = '/orders';

            const options = {
                method: 'POST',
                url: orderUrl,
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json'
                },
                data: data
            };

            axios.request(options).then(function (response) {
                console.log(response.data);
                window.location.href = response.data.payment_link;
            }).catch(function (error) {
                console.error(error?.response);
                const message = error?.response?.data?.message?.split(':')[1]
                alert(message)
            });
        } else {
            navigate('/sign_in');
        }
    }

    const handleBooking = () => {
        const bookingData = getBokingObj()
        axios({
            method: 'post',
            url: 'booking',
            data: bookingData
        })
            .then(res => {
                alert('booking confirmed')
                handleCancel()
            })
            .catch(err => {
                alert(`booking failed (${err.response.data.message})`)
            })
    }

    const getBokingObj = () => {
        const bookingData = {
            name: bookingDetails.name,
            mobile: bookingDetails.mobileNo,
            email: bookingDetails.mail,
            pickup: bookingDetails.pickupLocation,
            drop: bookingDetails.dropLocation,
            hrs: bookingDetails.time,
            kms: bookingDetails.distance,
            startDate: bookingDetails.startDate,
            startTime: bookingDetails.startTime,
            endDate: bookingDetails.endDate,
            endTime: bookingDetails.endTime,
            packageType: 'rental',
            packageTitle: '',
            username: bookingDetails.name,
            amount: selectedCarData.price,
            packageId: selectedCarData.id
        }
        return bookingData
    }

    const handlePayLater = () => {
        handleBooking();

    }


    // open download link in new tab function
    const openInNewTab = url => {
        window.open(url, '_blank', 'noopener,noreferrer');
    };

    
    const handleDownload = () => {
        let fileKey = "User_terms_and_conditions.pdf"
        window.open(`downloadAggrement/${fileKey}`, "_blank");
    }

    if (!selectedCarData) return null;

    console.log(open,"open value from props");

    return (
        <div>
            <Modal isOpen={open} toggle={() => handleCancel()} >
                <ModalHeader toggle={() => handleCancel()}>Booking Details</ModalHeader>

                {
                    openUploadTab ?
                        <>
                            <ModalBody>
                                <div className="row">
                                    <div className="col-4">Name :</div>
                                    <div className="col-auto">{bookingDetails.name}</div>
                                </div>
                                <div className="row">
                                    <div className="col-4">Pickup Location :</div>
                                    <div className="col-auto">{bookingDetails.pickupLocation}</div>
                                </div>
                                <div className="row">
                                    <div className="col-4">Drop Location :</div>
                                    <div className="col-auto">{bookingDetails.dropLocation}</div>
                                </div>
                                <div className="row">
                                    <div className="col-4">Contact No. :</div>
                                    <div className="col-auto">{bookingDetails.mobileNo}</div>
                                </div>
                                <div className="row">
                                    <div className="col-4">Email :</div>
                                    <div className="col-auto">{bookingDetails.mail}</div>
                                </div>
                                <div className="row">
                                    <div className="col-4">Start Date :</div>
                                    <div className="col-auto">{bookingDetails.startDate}</div>
                                </div>
                                <div className="row">
                                    <div className="col-4">End Date :</div>
                                    <div className="col-auto">{bookingDetails.endDate}</div>
                                </div>
                                <div className="row">
                                    <div className="col-4">Kilometers :</div>
                                    <div className="col-auto">{bookingDetails.distance}</div>
                                </div>
                                <div className="row">
                                    <div className="col-4">Hours :</div>
                                    <div className="col-auto">{bookingDetails.time}</div>
                                </div>
                                <div className="row">
                                    <div className="col-4">Total Amount :</div>
                                    <div className="col-auto">{selectedCarData.price}</div>
                                </div>
                                <br />
                                <strong>Vehicle Details</strong>
                                <div className="row">
                                    <div className="col-4">Name :</div>
                                    <div className="col-auto">{selectedCarData.name}</div>
                                </div>
                                <div className="row">
                                    <div className="col-4">Fuel Type:</div>
                                    <div className="col-auto">{selectedCarData.fuelType}</div>
                                </div>
                                <div className="row">
                                    <div className="col-4">Seater :</div>
                                    <div className="col-auto">{selectedCarData.seater}</div>
                                </div>
                            </ModalBody>
                            <ModalFooter>
                                <Button color="info"  onClick={() => handleDownload()}  >Download Agreement</Button>
                                <Button color="primary" onClick={() => handlePayNow()}>{'Pay Now'}</Button>{' '}
                                <Button color="primary" onClick={() => handlePayLater()}>{'Pay Later'}</Button>{' '}
                                <Button color="secondary" onClick={() => { handleCancel() }}>Cancel</Button>
                            </ModalFooter>
                        </>
                        :
                        <>
                            <UploadDoc setButton ={setButton} buttonDisable={buttonDisable} />
                            <ModalFooter>
                                <Button color="primary"  onClick={() => setUpload(open)} disabled={buttonDisable}>Next</Button>
                            </ModalFooter>
                        </>

                }



            </Modal>
        </div>
    )
}

export default BookingDetailsContainer