import axios from 'axios';
import React, { useState } from 'react'
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';

const EnquiryForm = ({ open, handleCancel }) => {
    const [name, setName] = useState('');
    const [mail, setMail] = useState('');
    const [mobileNo, setMobileNo] = useState('');
    const [requirements, setRequirements] = useState('');

    const handleSubmit = () => {

        const enquiryData = {
            name,
            requirements,
            email: mail,
            mobile: mobileNo
        }
        axios.post('/send-enquiry', enquiryData)
            .then(res => {
                alert('Enquiry successfully sent, will get back to you soon on provided email')
                clearData();
                handleCancel();
            }).catch(err => {
                console.log('err', err);
                alert(`error occurred- (${err.response.data.message})`)
            })
    }

    const clearData = () => {
        setName('');
        setMail('');
        setMobileNo('');
        setRequirements('');
    }

    return (
        <div>
            <Modal isOpen={open} toggle={() => handleCancel()} >
                <ModalHeader toggle={() => handleCancel()}>Send Enquiry</ModalHeader>
                <ModalBody>
                    <form>
                        <div className="form-group">
                            <label>Name</label>
                            <input className="form-control" value={name} onChange={(e) => setName(e.target.value)} />
                        </div>
                        <div className="form-group">
                            <label>Mobile</label>
                            <input className="form-control" value={mobileNo} onChange={(e) => setMobileNo(e.target.value)} />
                        </div>
                        <div className="form-group">
                            <label>Email</label>
                            <input className="form-control" type='email' value={mail} onChange={(e) => setMail(e.target.value)} />
                        </div>
                        <div className="form-group">
                            <label>Your Requirements</label>
                            <textarea className="form-control" value={requirements} onChange={(e) => setRequirements(e.target.value)} />
                        </div>

                    </form>
                </ModalBody>
                <ModalFooter>
                    <Button color="primary" onClick={() => handleSubmit()}>Send</Button>{' '}
                    <Button color="secondary" onClick={() => handleCancel()}>Cancel</Button>
                </ModalFooter>
            </Modal>
        </div>
    )
}

export default EnquiryForm
