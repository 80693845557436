import React, { useState } from "react";

export const Quotationinput = ({ addQuotations }) => {

    
    const [sNo, setSNo] = useState('')
    const [productdescription, setProductdescription] = useState('')
    const [quantity, setQuantity] = useState('')
    const [rate, setRate] = useState('')
    const [amount, setAmount] = useState('')

    const handleSave = () => {
        const data = {
            sNo,
            productdescription,
            quantity,
            rate,
            amount
        }
        addQuotations(data)
    }

    return(
        
        // <div>
        //     Shiv
        // </div>

        <div>
        <div className="row">
            <div className="col-md-2">
                S No.
                <input
                    type='number'
                    className="form-control"
                    placeholder="S No"
                    value={sNo}
                    onChange={(e) => setSNo(e.target.value)}
                />
            </div>
            <div className="col-md">
                Product Description
                <input
                    className="form-control"
                    placeholder="Product Description"
                    value={productdescription}
                    onChange={(e) => setProductdescription(e.target.value)}
                />
            </div>
        </div>
        <div className="row">
            <div className="col-md">
                Quantity
                <input
                    type='number'
                    className="form-control"
                    placeholder="Quantity"
                    value={quantity}
                    onChange={(e) => setQuantity(e.target.value)}
                />
            </div>
            <div className="col-md">
                Rate
                <input
                    className="form-control"
                    placeholder="Rate"
                    value={rate}
                    onChange={(e) => setRate(e.target.value)}
                />
            </div>
            <div className="col-md">
                Amount
                <input
                type='number'
                    className="form-control"
                    placeholder="Amount"
                    value={amount}
                    onChange={(e) => setAmount(e.target.value)}
                />
            </div>
        </div>
        <button onClick={() => handleSave()
        }>
            Save Quotation
        </button>
    </div>

    );
}