import axios from 'axios';
import React, { useEffect, useState } from 'react';
import LoaderComp from '../miscellaneous/loader';
import { useSelector } from 'react-redux';
import { useCookies } from "react-cookie";

const PaymentStatus = () => {

    const [customerName, setCustomerName] = useState('');
    const [orderAmount, setOrderAmount] = useState('');
    const [orderCurrency, setOrderCurrency] = useState('');
    const [orderStatus, setOrderStatus] = useState('ACTIVE');
    const [cookies, setCookie] = useCookies()

    const bookingDetails = useSelector((state) => state.bookingDetails)
    useEffect(() => {
        const searchValue = window.location.search;

        const order_id = searchValue.split('&')[0].substr(10);
        // for local host
        // https://cors-anywhere.herokuapp.com/corsdemo
        // const url = `https://cors-anywhere.herokuapp.com/https://sandbox.cashfree.com/pg/orders/${order_id}`;

        // for prod
        const url = `/orders/${order_id}`;

        const options = {
            headers: {
                Accept: 'application/json',
            }
        };

        axios.get(url, options).then(function (response) {
            console.log(response.data);
            if (cookies && cookies.bookingDetails && response.data.order_status === 'PAID') {
                axios({
                    method: 'post',
                    url: 'booking',
                    data: cookies.bookingDetails
                })
                    .then(res => {
                        alert('booking confirmed');
                        setCustomerName(response.data.customer_details?.customer_name);
                        setOrderAmount(response.data.order_amount);
                        setOrderCurrency(response.data.order_currency);
                        setOrderStatus(response.data.order_status);
                    })
            } else {
                setCustomerName(response.data.customer_details?.customer_name);
                setOrderAmount(response.data.order_amount);
                setOrderCurrency(response.data.order_currency);
                setOrderStatus(response.data.order_status);
            }
        }).catch(function (error) {
            console.error(error);
        });
    }, []);


    return customerName === '' ? <LoaderComp /> : (
        <div className='container p-5'>
            <h3>Payment Status</h3>
            <div className="mt-3">
                {`Hi ${customerName} your payment of ${orderCurrency} ${orderAmount} `}
                {orderStatus === 'ACTIVE' ? 'is cancelled' : orderStatus === 'PAID' ? 'has been recieved successfully.' : 'has been expired.'}
                <div className="d-flex mt-3">
                    <a href='/'>Go to Home Page</a>
                </div>
            </div>
        </div>
    );
};

export default PaymentStatus;
