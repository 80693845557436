import React, { useState } from 'react'

import { useNavigate } from 'react-router';
import './header.scss'
import whiteHamburger from '../../assets/whiteHamburger.svg';
import HamburgerMenu from './hamburgerMenu';
import logo from '../../assets/company_logo.jpeg'

const Header = () => {
	const [openMenu, setOpenMenu] = useState(false)

	const navigate = useNavigate();

	return (
		<div className={
			`container-fluid header 
			${(window.location.pathname === "/sign_in" || window.location.pathname === "/sign_up") ? 'bg-dark' : 'bg-darkblue'}`}>
			<div className='row justify-content-around'>
				<span className='col' >
					<img className='h-80' src={logo} onClick={() => navigate('/')} />
				</span>
				<span className='col justify-content-end item-container'>

					{openMenu ?
						<HamburgerMenu closeHamburger={() => setOpenMenu(false)} />
						:
						<img src={whiteHamburger} className='items my-auto' onClick={() => setOpenMenu(true)} />
					}
				</span>
			</div>
		</div>
	)
}

export default Header
