import React from 'react';
import { galleryImages } from '../../utilsData/galleryImages';
import Footer from '../footer';
import Header from '../header';
import './gallery.scss';

const Gallery = () => {
    return (
        <div>
            <Header />
            <div className="images-container">

                {galleryImages.map(item =>
                    <div className="image-container">
                        <img src={item.image.default} />
                    </div>
                )}
            </div>
            <Footer />
        </div>
    )
}

export default Gallery
