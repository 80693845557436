import React from 'react'
import OwlCarousel from 'react-owl-carousel';

import './carousel.scss'
import { testimonialData } from '../../../utilsData/testimonialData';

const Carousel = ({noOfItems}) => {

    return (
        <OwlCarousel
            autoplay={true}
            autoplayTimeout={3000}
            autoplayHoverPause={true}
            items={noOfItems}
            stagePadding={20}
            center={true}
            nav={false}
            margin={50}
            dots={true}
            loop={true}
            className='pt-5'
        >
            {testimonialData.map(item => {
                return <div className="card">
                    {/* <div className="img-card"> <img src={item.image} alt="" /> </div> */}
                    <div className=" mt-4 ">
                        {item.text.map(ele => {
                            return <div className="testimonial mb-2"> {ele} </div>
                        })}
                    </div >
                    <div className="name"> {item.name} </div>
                </div>
            })}
        </OwlCarousel>

    )
}
export default Carousel