export const formatDiscount = (discount) => {
    return `${discount.name} (${discount.type === 'price' ? '₹' : ''}${discount.value}${discount.type === 'percent' ? '%' : ''} off)`
}

export const getPrice = (
    basePrice1,
    basePrice2,
    basePrice3,
    extraKmCharge,
    extraHourCharge,
    distance,
    time,
) => {
    if (distance <= 120 && time <= 12) {
        return basePrice1;
    } else if (distance <= 185 && time <= 18) {
        return basePrice2;
    } else if (distance <= 250 && time <= 24) {
        return basePrice3;
    } else {
        let total
        if (time >= 24 && distance >= 250) {
            total = +basePrice3 + ((time - 24) * extraHourCharge) + ((distance - 250) * extraKmCharge)
        } else if (time > 24) {
            total = +basePrice3 + ((time - 24) * extraHourCharge)
        } else {
            total = +basePrice3 + ((distance - 250) * extraKmCharge)
        }

        return Number(total).toFixed(2)
    }
}
