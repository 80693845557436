import React from 'react'

import './phoneComponent.scss'

const phoneComponent = () => {
    return (
        <div className="icons-container">
            <div className="whatsApp" >
                <a className="fa fa-whatsapp" target='_blank' href="https://wa.me/919109123404"/>
            </div>
            <div className='phone-component'>
                <a className="fa fa-phone" href="tel:+0731-4035356" />
            </div>
        </div>
    )
}

export default phoneComponent
