import React, { useEffect, useState } from 'react'
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import './occupancyForm.scss';

const OccupancyForm = ({ open, data, confirm, cancel }) => {
   
    const [total, setTotal] = useState('')
    const [available, setAvailable] = useState('')

    useEffect(() => {
        updateData()
    }, [data])

    const handleSubmit = () => {
        const data = {
            total,
            available
        }
        console.log('data', data);
        confirm(data);
        updateData();
    }

    const handleCancel = () => {
        updateData();
        cancel();
    }

    const updateData = () => {
        setTotal(data.total);
        setAvailable(data.available);
    }

    return (
        <div>
            <Modal isOpen={open} toggle={() => cancel()} >
                <ModalHeader toggle={() => cancel()}>Update Occupancy</ModalHeader>
                <ModalBody>
                    <form>
                        <div className="form-group">
                            <label htmlFor="name">Total</label>
                            <input className="form-control" name='modelName' onChange={(e) => setTotal(e.target.value)} value={total} />
                        </div>
                        
                        <div className="form-group">
                            <label htmlFor="name">Available</label>
                            <input className="form-control" name='basePrice' onChange={(e) => setAvailable(e.target.value)} value={available} />
                        </div>
                    </form>
                </ModalBody>
                <ModalFooter>
                    <Button color="primary" onClick={() => handleSubmit()}>Save</Button>{' '}
                    <Button color="secondary" onClick={() => handleCancel()}>Cancel</Button>
                </ModalFooter>
            </Modal>
        </div>
    );
}

export default OccupancyForm;