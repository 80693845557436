export const tourAndTravelPolicies =
    <div className="">
        <div className='title'>
            Tour & Travel Policies
        </div>
        <h4>Payment Policy:</h4>
        <ul>
            Initial Deposit: INR–5000/- need to deposit to process the booking.
        </ul>
        <ul>
            On Confirmation: 50% advance payment as booking confirmation.
        </ul>
        <ul>
            Balance payment will clear on arrival.
        </ul>
        <h4>
            Note:
        </h4>
        <ul>
            After depositing the payment, please share the scan copy of deposit slip.
        </ul>
        <h4>Term & Condition:</h4>
        <ul>
            Above rates are calculated on the basis of hotels mentioned and except long Holidays & Long Weekends. In case room type is not mentioned, calculation is based on the base category room in the hotel.
        </ul>
        <ul>
            Booking will be conformed only after receiving the advance payment and on availability of hotels mentioned.
        </ul>
        <ul>
            In Case of non-availability of room in chosen hotel then similar hotel category will be given to you.
        </ul>
        <ul>
            Hotels for each category are selected as per the budget and through our feedback system. We suggest you to check reviews and location of hotels mentioned to make sure it is as per your preferences before confirming the package.
        </ul>
        <ul>
            We do not have any hidden charges except your lunch, and personal expenses. During the Journey if someone asks for extra money you may inform us and we will not be responsible if you pay the amount without our concern.
        </ul>
        <ul>
            Any extra bed / breakfast for kids which is not mentioned has to be paid directly by guest as per the hotel policy.
        </ul>
        <ul>
            Any Natural calamities we will not be liable for the same.
        </ul>
        <ul>
            No accommodation, meal or cost of transport would be paid or reimbursed or compensated.
        </ul>
        <ul>
            All Buffet Meal / Room Service will be provided as per hotel policy.
        </ul>
        <h4> Cancellation Policy:</h4>
        <ul>
            Cancellation charges of 50% for cancellation if a confirmed reservation is cancelled between 30 to 15 days from the date of arrival.
        </ul>
        <ul>
            Cancellation charges of 75% for cancellation if a confirmed reservation is cancelled between 15 to 7 days from the date of arrival.
        </ul>
        <ul>
            Cancellation charges of 100% for cancellation if a confirmed reservation is cancelled within week to the date of arrival.
        </ul>
        <ul>
            If booking is cancelled 30 days before then per person communication service charges applicable per person INR 1000/-. Followed by cancellation policy
        </ul>
        <ul>
            No shows and early departures- 100% of the entire day.
        </ul>
    </div>