import React, { useEffect, useState } from 'react'

import ServicePageBanner from '../miscellaneous/servicePageBanner'
import './tourPackages.scss'
import CarRental from '../../assets/screen2.jpg'
import ServicePageText from '../miscellaneous/servicePageText'
import Footer from '../footer'
import PackageCard from '../packageCard'
import axios from 'axios'
import PackageForm from '../chooseCar/packageForm'
import PackageDetailsContainer from '../chooseCar/packageDetailsContainer'
import LoginModal from '../loginModal'
import { useCookies } from "react-cookie";
import { useNavigate } from 'react-router';

const TourPackages = () => {
    const [tours, setTours] = useState([])
    const [openPackageForm, setOpenPackageForm] = useState(-1)
    const [userData, setUserData] = useState()
    const [cookies, setCookie] = useCookies()
    const [packageDetails, setPackageDetails] = useState({})
    const [openDetailsContainer, setOpenDetailsContainer] = useState(false)
    const [openLoginModal, setOpenLoginModal] = useState(false)
    const [selectedPackageIndex, setSelectedPackageindex] = useState(-1)

    useEffect(() => {
        window.scrollTo(0, 0);

        axios.get('tours')
            .then(res => setTours(res.data))
            if (cookies && cookies.authorization) {
                setUserData(cookies.userData);
            }
    }, [])

    const navigate = useNavigate();

    const handleCancel = () => {
        setOpenPackageForm(-1)
        setPackageDetails({})
    }

    const handleSubmit = () => {
        setSelectedPackageindex(openPackageForm)
        setOpenPackageForm(-1)
        setOpenDetailsContainer(true)
    }
    const handlePackageBookNow = (index) => {
        if (cookies && cookies.authorization) {
            setOpenPackageForm(index)
        } else {
            setOpenLoginModal(true)
        }
    }

    const handleLogin = () => {
        setCookie('redirectedFromTaxiServices', true)
        navigate('/sign_in')
    }

    const heading = 'Tour & Travel Packages'
    const description = [
        `The Standard Journey, with a strong presence in inbound travel trade and corporate segment, today has excelled its branches over India. The company with its professionally managed travel engine specializes mainly in organizing Adventure, Cultural, Religious, hill station & wildlife tours in India through a sprawling network. `,
        `It offers 24 X 7 hours services that include travel planning, itinerary design, hotel bookings, ticket reservations and transport facilities. It also provides holiday packages, customized as per client’s need and budget. The Standard Journey offers Special Interest Tours for travelers who want to experience something based on their special interest. These are tours that are different from the usual touristy stuff & focus on a particular activity for which a particular region or place is famous. From the joy of tea tasting to taking a tribal tour of Central India, Gujarat & Northeast India, you can plan a tour based on the specific interest you have in mind. Our expertly created Special Interest Tours also include photography, angling & fishing, wine tasting, snake, spice plantation, butterfly & flower tours. `,
        `With a rich and diverse history and culture, India is a perfect destination for heritage tours. There are a plethora of heritage sites in India including one of the Seven Wonders of the World, Taj Mahal that people come to witness from all over the world. With our best heritage tour packages, explore the top historical heritage sites and cultural heritage in India to learn more about the country. We offer the best of our services in the heritage tours by arranging expert tour guides, transportation, and accommodations and ensuring a memorable experience.`
    ]

    return (
        <div>
            <ServicePageBanner banner={CarRental} />
            <ServicePageText heading={heading} description={description} />
            <div className="packageCards">
                {
                    tours.map((item, index) => <PackageCard data={item} buttonText='Book Now' onButtonClick={() => handlePackageBookNow(index)}/>)
                }
            </div>
            <PackageForm open={openPackageForm !== -1} selectedPackageData={tours[openPackageForm]} handleCancel={() => handleCancel()} handleSubmit={() => handleSubmit()} packageDetails={packageDetails} setPackageDetails={setPackageDetails} userData={userData} />
            <PackageDetailsContainer
                open={openDetailsContainer}
                selectedPackageData={tours[selectedPackageIndex]}
                packageDetails={packageDetails}
                handleCancel={() => setOpenDetailsContainer(false)}
                packageType={'taxi'}
            />
            <LoginModal open={openLoginModal} handleCancel={() => setOpenLoginModal(false)} handleLogin={() => handleLogin()} />
            <Footer />
        </div>
    )
}


export default TourPackages
