import React from "react";

import { Route, Routes } from "react-router-dom";

import AboutUs from "./components/aboutus";
import ChooseCar from "./components/chooseCar";
import Contact from "./components/contact";
import Home from "./components/home";
import TourPackages from "./components/tourPackages";
import PhoneComponent from "./components/miscellaneous/phoneComponent";
import TaxiServices from "./components/taxiServices";
import CarRental from "./components/carRental";
import InvoiceGenerator from './components/invoiceGenerator'

import './App.scss'
import SignIn from "./components/authPages/signIn";
import SignUp from "./components/authPages/signUp";
import ResetPassword from "./components/authPages/resetPassword";
import DashBoard from "./components/dashboard";
import AdminDashBoard from "./components/adminDashboard";
import Gallery from "./components/gallery";
import EnquiryButton from "./components/miscellaneous/enquiryButton";
import TncPage from "./components/tnc";
import { CookiesProvider } from "react-cookie";
import { ProtectedRoute, AdminRoute} from "./components/protectedRoute"
import TourAndTravelPolicies from "./components/tnc/tourAndTravelPolicies";
import Payment from "./components/payment";
import PaymentStatus from "./components/payment/paymentStatus";

function App() {

  // console.log = () => null;
  // console.info = () => null;
  // console.warn = () => null;
  // console.log = () => null;

  return (
      <CookiesProvider>
      {/* <Header /> */}
        <Routes>
          <Route path='/' element={<Home />} />
          <Route path='/about_us' element={<AboutUs />} />
          <Route path='/choose_car' element={<ChooseCar />} />
          <Route path='/tour_packages' element={<TourPackages />} />
          <Route path='/contact' element={<Contact />} />
          <Route path='/car_rental' element={<CarRental />} />
          <Route path='/taxi_services' element={<TaxiServices />} />
          {/* <Route path='/invoice_generator' element={<InvoiceGenerator />} /> */}
          <Route path='/gallery' element={<Gallery />} />
          <Route path='/sign_in' element={<SignIn />} />
          <Route path='/sign_up' element={<SignUp />} />
          <Route path='/reset_password' element={<ResetPassword />} />
          <Route path='/rental_car_policies' element={<TncPage />} />
          <Route path='/tour_and_travel_policies' element={<TourAndTravelPolicies />}/>
          <Route path='/dashboard' element={<ProtectedRoute><DashBoard /></ProtectedRoute>} />
          <Route path='/admin_dashboard' element={<AdminRoute><AdminDashBoard/></AdminRoute>} />
          <Route path='/paynow' element={<Payment/>} />
          <Route path='/payment_status' element={<PaymentStatus/>} />
          
        </Routes>
      <EnquiryButton />
      <PhoneComponent />
      </CookiesProvider>
  );
}

export default App;
