import React, { useState, useEffect } from 'react'
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';


const PackageForm = ({ open, selectedPackageData, handleCancel, packageDetails, setPackageDetails, handleSubmit, userData }) => {
    const [name, setName] = useState('');
    const [mail, setMail] = useState('');
    const [mobileNo, setMobileNo] = useState('');
    const [pickupLocation, setPickupLocation] = useState('');
    const [dropLocation, setDropLocation] = useState('');
    const [kms, setKms] = useState('');
    const [hrs, setHrs] = useState('');
    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');
    const [packageId, setPackageId] = useState('');
    const [amount, setAmount] = useState('');


    useEffect(() => {

        if (Object.keys(packageDetails)?.length) {
            setName(packageDetails.name)
            setMail(packageDetails.mail)
            setMobileNo(packageDetails.mobileNo)
            setPickupLocation(packageDetails.pickupLocation)
            setDropLocation(packageDetails.dropLocation)
            setKms(packageDetails.kms)
            setHrs(packageDetails.hrs)
            setStartDate(packageDetails.startDate)
            setEndDate(packageDetails.endDate)
            setPackageId(packageDetails.packageId)
            setAmount(packageDetails.amount)
        } else if (userData) {
            setName(userData.name)
            setMail(userData.email)
            setMobileNo(userData.phone)
            setAmount(selectedPackageData?.price)
            setPackageId(selectedPackageData?.id)
        }

    }, [selectedPackageData])

    const handleSubmitClick = () => {
        //api to send enquiry
        // clearData();
        setPackageDetails({ name, mail, mobileNo, pickupLocation, dropLocation, kms, hrs, startDate, endDate, packageId, amount })
        handleSubmit()
    }

    const handleCancelClick = () => {
        clearData()
        handleCancel()
    }

    const clearData = () => {
        setName('');
        setMail('');
        setMobileNo('');
        setPickupLocation('');
        setDropLocation('');
        setKms('');
        setHrs('');
        setStartDate('');
        setEndDate('');
        setPackageId('');
    }

    return (
        <div>
            <Modal isOpen={open} toggle={() => handleCancel()} >
                <ModalHeader toggle={() => handleCancel()}>Journey Details</ModalHeader>
                <ModalBody>
                    <form>
                        <div className="form-group">
                            <label>Name</label>
                            <input
                                className="form-control"
                                value={name}
                                placeholder='Name'
                                onChange={(e) => setName(e.target.value)}
                            />
                        </div>
                        <div className="form-group">
                            <label>Mobile</label>
                            <input
                                className="form-control"
                                value={mobileNo}
                                placeholder="Mobile No."
                                onChange={(e) => setMobileNo(e.target.value)}
                            />
                        </div>
                        <div className="form-group">
                            <label>Email</label>
                            <input
                                className="form-control"
                                type='email'
                                placeholder="email"
                                value={mail}
                                onChange={(e) => setMail(e.target.value)}
                            />
                        </div>
                        <div className="form-group">
                            <label> Pickup</label>
                            <input
                                className="form-control"
                                placeholder="Pickup location"
                                value={pickupLocation}
                                onChange={(e) => setPickupLocation(e.target.value)}
                            />
                        </div>
                        <div className="form-group">
                            <label> Drop</label>
                            <input
                                className="form-control"
                                placeholder="Drop location"
                                value={dropLocation}
                                onChange={(e) => setDropLocation(e.target.value)}
                            />
                        </div>
                        <div className="form-group">
                            <label>KMs</label>
                            <input
                                type="text"
                                className="form-control"
                                value={kms}
                                placeholder='kms'
                                onChange={(e) => setKms(e.target.value)}
                            />
                            <label>Hrs</label>
                            <input
                                type="text"
                                className="form-control"
                                value={hrs}
                                placeholder='hrs'
                                onChange={(e) => setHrs(e.target.value)}
                            />
                        </div>
                        <div className="form-group">
                            <label>Start Date</label>
                            <input
                                type="date"
                                className="form-control"
                                value={startDate}
                                onChange={(e) => setStartDate(e.target.value)}
                            />
                            <label>End Date</label>
                            <input
                                type="date"
                                className="form-control"
                                value={endDate}
                                onChange={(e) => setEndDate(e.target.value)}
                            />
                            {/* <label> Time</label>
                            <input
                                type="time"
                                className="form-control"
                                value={time}
                                onChange={(e) => setTime(e.target.value)}
                            /> */}
                        </div>

                    </form>
                </ModalBody>
                <ModalFooter>
                    <Button color="primary" onClick={() => handleSubmitClick()}>Book</Button>{' '}
                    <Button color="secondary" onClick={() => handleCancelClick()}>Cancel</Button>
                </ModalFooter>
            </Modal>
        </div>
    )
}

export default PackageForm