import React, { useState, useEffect } from 'react'
import CarComponent from './carComponent'

// import { carsData } from '../../utilsData/carsData'
import './rightContainer.scss'
import BookingForm from '../bookingForm'
import BookingDetailsContainer from '../BookingDetailsContainer'
import { useCookies } from "react-cookie";
import LoginModal from '../loginModal'
import { useNavigate } from 'react-router';
import { getPrice } from '../../utils/helper'
import Loader from '../miscellaneous/loader'

const RightContainer = ({ distance, time, seaterFilter, fuelTypeFilter, setDistance, setTime }) => {

  const [openForm, setOpenForm] = useState(-1)
  const [openLoginModal, setOpenLoginModal] = useState(false)
  const [selectedCarIndex, setSelectedCarIndex] = useState(-1)
  const [openDetailsContainer, setOpenDetailsContainer] = useState(false)
  const [carsData, setCarsData] = useState([]);
  const [bookingDetails, setBookingDetails] = useState({});
  const [userLoggedIn, setUserLoggedIn] = useState(false)
  const [cookies, setCookie] = useCookies()
  const [userData, setUserData] = useState()
  const [showLoader, setShowLoader] = useState(false)

  useEffect(() => {
    setShowLoader(true)
    fetch(`/cars?fuelType=${fuelTypeFilter}&seater=${seaterFilter}`, {
      method: 'get',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      credentials: "same-origin"
    })
      .then(function (response) {
        if (response.status !== 200) {
          response.json().then(function (err) {
            console.log(err.message);
          })
        } else {
          response.json().then(function (data) {
            updateCarsData(data);
          })
        }
        setShowLoader(false)
      });

    if (cookies && cookies.authorization) {
      setUserLoggedIn(true);
      setUserData(cookies.userData);
    }
  }, [seaterFilter.length, fuelTypeFilter.length])

  useEffect(() => {
    if (carsData) {
      updateCarsData(carsData)
    }
  }, [distance, time])


  const updateCarsData = (data) => {
    const result = data && data.map(item => {
      let price = getPrice(
        item.basePrice1,
        item.basePrice2,
        item.basePrice3,
        item.extraKmCharge,
        item.extraHourCharge,
        distance,
        time
      )
      item.price = price
      return item
    })
    setCarsData(result)
  }

  const navigate = useNavigate();

  const handleOpenForm = (index) => {
    if (userLoggedIn) {
      setOpenForm(index)
      setSelectedCarIndex(index)
    } else {
      setOpenLoginModal(true)
    }
  }

  const handleLogin = () => {
    setCookie('redirectedFromBooking', true)
    navigate('/sign_in')
  }
  const handleCancel = () => {
    setOpenForm(-1)
    setBookingDetails({})
    setSelectedCarIndex(-1)
  }

  const handleSubmit = () => {
    setOpenForm(-1)
    setOpenDetailsContainer(true)
  }

  return (
    <>
      {showLoader &&
        <Loader />}
      <div className='car-details-container'>
        {carsData.map((item, index) => <CarComponent key={index} data={item} index={index} forAdmin={false} handleButtonClick={() => handleOpenForm(index)} />)}
      </div>
      <BookingForm
        open={openForm !== -1}
        selectedCarData={carsData[openForm]}
        handleCancel={() => handleCancel()}
        handleSubmit={() => handleSubmit()}
        bookingDetails={bookingDetails}
        setBookingDetails={setBookingDetails}
        userData={userData}
        distance={distance}
        time={time}
        setDistance={setDistance}
        setTime={setTime}
      />
      <BookingDetailsContainer open={openDetailsContainer} selectedCarData={carsData[selectedCarIndex]} bookingDetails={bookingDetails} handleCancel={() => setOpenDetailsContainer(false)} />
      <LoginModal open={openLoginModal} handleCancel={() => setOpenLoginModal(false)} handleLogin={() => handleLogin()} />
    </>
  )
}

export default RightContainer
