import React, { useState, useEffect } from 'react'
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';

const BookingForm = ({
    open,
    selectedCarData,
    handleCancel,
    bookingDetails,
    setBookingDetails,
    handleSubmit,
    userData,
    time,
    distance,
    setDistance,
    setTime
}) => {

    const [name, setName] = useState('');
    const [mail, setMail] = useState('');
    const [mobileNo, setMobileNo] = useState('');
    const [pickupLocation, setPickupLocation] = useState('');
    const [dropLocation, setDropLocation] = useState('');
    // const [kms, setKms] = useState('');
    // const [hrs, setHrs] = useState('');
    const [startDate, setStartDate] = useState('');
    const [startTime, setStartTime] = useState('');
    const [endDate, setEndDate] = useState('');
    const [endTime, setEndTime] = useState('');

    useEffect(() => {

        if (Object.keys(bookingDetails)?.length) {
            setName(bookingDetails.name)
            setMail(bookingDetails.mail)
            setMobileNo(bookingDetails.mobileNo)
            setPickupLocation(bookingDetails.pickupLocation)
            setDropLocation(bookingDetails.dropLocation)
            // setKms(bookingDetails.kms)
            // setHrs(bookingDetails.hrs)
            setStartDate(bookingDetails.startDate)
            setEndDate(bookingDetails.endDate)
            // setTime(bookingDetails.time)
        } else if (userData) {
            setName(userData.name)
            setMail(userData.email)
            setMobileNo(userData.phone)
        }

    }, [selectedCarData])

    useEffect(() => {
        if (startDate && startTime && endDate && endTime) {
            const start = new Date(
                startDate.substring(0, 4),
                startDate.substring(5, 7) - 1,
                startDate.substring(8, 10),
                startTime.substring(0, 2),
                startTime.substring(3, 5)
            )
            const end = new Date(
                endDate.substring(0, 4),
                endDate.substring(5, 7) - 1,
                endDate.substring(8, 10),
                endTime.substring(0, 2),
                endTime.substring(3, 5)
            )

            diff_hours(start, end)
        }
    }, [startDate, startTime, endDate, endTime])

    const handleSubmitClick = () => {
        //api to send enquiry
        // clearData();
        setBookingDetails({ name, mail, mobileNo, pickupLocation, dropLocation, distance, time, startDate, endDate, startTime, endTime })
        handleSubmit()
    }

    const handleCancelClick = () => {
        clearData()
        handleCancel()
    }

    const clearData = () => {
        setName('');
        setMail('');
        setMobileNo('');
        setPickupLocation('');
        setDropLocation('');
        // setKms('');
        // setHrs('');
        setStartDate('');
        setEndDate('');
    }

    const diff_hours = (dt2, dt1) => {
        var diff = (dt2.getTime() - dt1.getTime()) / 1000;
        diff /= (60 * 60);
        setTime(Math.abs(Math.round(diff)))
    }

    return (
        <div>
            <Modal isOpen={open} toggle={() => handleCancel()} >
                <ModalHeader toggle={() => handleCancel()}>Journey Details</ModalHeader>
                <ModalBody>
                    <form>
                        <div className="form-group">
                            <label>Name</label>
                            <input
                                className="form-control"
                                value={name}
                                placeholder='Name'
                                onChange={(e) => setName(e.target.value)}
                            />
                        </div>
                        <div className="form-group">
                            <label>Mobile</label>
                            <input
                                className="form-control"
                                value={mobileNo}
                                placeholder="Mobile No."
                                onChange={(e) => setMobileNo(e.target.value)}
                            />
                        </div>
                        <div className="form-group">
                            <label>Email</label>
                            <input
                                className="form-control"
                                type='email'
                                placeholder="email"
                                value={mail}
                                onChange={(e) => setMail(e.target.value)}
                            />
                        </div>
                        <div className="form-group">
                            <label> Pickup</label>
                            <input
                                className="form-control"
                                placeholder="Pickup location"
                                value={pickupLocation}
                                onChange={(e) => setPickupLocation(e.target.value)}
                            />
                        </div>
                        <div className="form-group">
                            <label> Drop</label>
                            <input
                                className="form-control"
                                placeholder="Drop location"
                                value={dropLocation}
                                onChange={(e) => setDropLocation(e.target.value)}
                            />
                        </div>
                        <div className="form-group">
                            <label>KMs</label>
                            <input
                                type="text"
                                className="form-control"
                                value={distance}
                                placeholder='kms'
                                onChange={(e) => setDistance(e.target.value)}
                            />
                        </div>
                        <div className="form-group" style={{ display: 'flex', justifyContent: 'space-around' }}>
                            <div>
                                <label>Start Date</label>
                                <input
                                    type="date"
                                    className="form-control"
                                    value={startDate}
                                    onChange={(e) => setStartDate(e.target.value)}
                                />
                            </div>
                            <div>
                                <label>Start Time</label>
                                <input
                                    type="time"
                                    className="form-control"
                                    value={startTime}
                                    onChange={(e) => setStartTime(e.target.value)}
                                />
                            </div>
                        </div>
                        <div className="form-group" style={{ display: 'flex', justifyContent: 'space-around' }}>
                            <div>
                                <label>End Date</label>
                                <input
                                    type="date"
                                    className="form-control"
                                    value={endDate}
                                    onChange={(e) => setEndDate(e.target.value)}
                                />
                            </div>
                            <div>
                                <label>End Time</label>
                                <input
                                    type="time"
                                    className="form-control"
                                    value={endTime}
                                    onChange={(e) => setEndTime(e.target.value)}
                                />
                            </div>
                        </div>
                        <div>
                            <label>Total Hrs: {time}</label>
                        </div>
                    </form>
                </ModalBody>
                <ModalFooter>
                    <Button color="primary" onClick={() => handleSubmitClick()}>Book</Button>{' '}
                    <Button color="secondary" onClick={() => handleCancelClick()}>Cancel</Button>
                </ModalFooter>
            </Modal>
        </div >
    )
}

export default BookingForm
