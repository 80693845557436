import React, { useState, useEffect, useContext } from 'react';
import './signIn.scss'
import { useNavigate } from 'react-router';
import logo from '../../assets/company_logo.jpeg'
import Header from '../header';
import { useCookies } from "react-cookie";

const SignIn = () => {

    const [userName, setUserName] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState({});

    const [cookies, setCookie] = useCookies();

    const navigate = useNavigate();

    // Mobile login states
    const [openMobTab, setMobTab] = useState(true);
    const [openEntOtp, setEntOtp] = useState(false);

    const [getMobNo, setMobNo] = useState('');
    const [getOtp, setOtp] = useState(new Array(4).fill(""));



    useEffect(() => {
        if (cookies && cookies.authorization) {
            if (cookies.isAdmin === 'true') {
                navigate('/admin_dashboard')
            } else {
                navigate('/dashboard')
            }
        }
    }, [])

    const validateData = (data) => {
        let errors = {}
        if (data.userName === '') {
            errors.userName = "Email can't be blank";
        }
        if (data.password === '') {
            errors.password = "Password can't be blank";
        }

        if (data.getMobNo === '') {
            errors.getMobNo = "Mobile can't be blank";
        }
        setError(errors)
        if (Object.keys(errors).length) {
            return false;
        } else {
            return true;
        }
    }

    // handle OTP change in input field

    const handleOtpChange = (element, index) => {
        if (isNaN(element.value)) return false;
        setOtp([...getOtp.map((d, idx) => (idx === index ? element.value : d))]);

        // focus next input
        if (element.nextSibling) {
            element.nextSibling.focus();
        }
    }

    const handleChange = (e) => {
        const name = e.target.name;
        const value = e.target.value;
        if (name === 'userName') {
            setUserName(value);
        } else if (name === 'password') {
            setPassword(value);
        }
        else if (name === 'mobileNo') {
            setMobNo(value);
        }


        let errorRef = error;
        errorRef[name] = null;
        setError(errorRef);
    }

    // handle get otp for verification
    const handleGetOtp = () => {
        const data = {
            getMobNo
        }

        if (validateData(data)) {
            fetch('/loginOtp', {
                method: 'post',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({ "phoneNumber": getMobNo }),
                credentials: "same-origin"
            })
                .then(function (response) {
                    if (response.status !== 200) {
                        response.json().then(function (err) {
                            setError({ loginMsg: "OTP not received" })
                        })
                    } else {
                        response.json().then(function (data) {
                            console.log("OTP sent", data)
                            setEntOtp(true);

                        })
                    }
                });
        }
    }

    // Handle Otp login
    const handleOtpLogin = () => {
        const otpMerg = getOtp.join("");
        console.log(getMobNo, otpMerg, "check");

        fetch('/verifyOtp', {
            method: 'post',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({ "phoneNumber": getMobNo, "otp": otpMerg }),
            credentials: "same-origin"
        })
            .then(function (response) {
                if (response.status !== 200) {
                    response.json().then(function (err) {
                        setError({ loginMsg: "Login is failed" })
                    })
                } else {
                    response.json().then(function (data) {
                        console.log(data, data.isAdmin, "check mobile login")
                        if (data.isAdmin) {
                            navigate('/admin_dashboard')
                        } else {
                            if (cookies.redirectedFromBooking === 'true') {
                                setCookie('redirectedFromBooking', false)
                                navigate('/choose_car')
                            } else if (cookies.redirectedFromTaxiServices === 'true') {
                                setCookie('redirectedFromTaxiServices', false)
                                navigate('/taxi_services')
                            } else if (cookies.redirectedFromTourPackages === 'true') {
                                setCookie('redirectedFromTourPackages', false)
                                navigate('/tour_packages')
                            }
                            else {
                                setCookie('redirectedFromBooking', false)
                                navigate('/dashboard')
                            }
                        }

                    })
                }
            });

    }
    const handleLogin = () => {
        const data = {
            userName,
            password
        }

        if (validateData(data)) {
            fetch('/login', {
                method: 'post',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({ "username": userName, "password": password }),
                credentials: "same-origin"
            })
                .then(function (response) {
                    if (response.status !== 200) {
                        response.json().then(function (err) {
                            setError({ loginMsg: "Failed to login" })
                        })
                    } else {
                        response.json().then(function (data) {
                            console.log(data, "Checkk email login")
                            if (data.isAdmin) {
                                navigate('/admin_dashboard')
                            } else {
                                if (cookies.redirectedFromBooking === 'true') {
                                    setCookie('redirectedFromBooking', false)
                                    navigate('/choose_car')
                                } else if (cookies.redirectedFromTaxiServices === 'true') {
                                    setCookie('redirectedFromTaxiServices', false)
                                    navigate('/taxi_services')
                                } else if (cookies.redirectedFromTourPackages === 'true') {
                                    setCookie('redirectedFromTourPackages', false)
                                    navigate('/tour_packages')
                                }
                                else {
                                    setCookie('redirectedFromBooking', false)
                                    navigate('/dashboard')
                                }
                            }
                        })
                    }
                });
        }
    }

    return (
        <>
            <Header />
            <div className="container-fluid signin-container bg-dark">
                <div className="card card-login mx-auto text-center bg-dark">
                    <div className="card-header mx-auto bg-dark">
                        <span> <img src={logo} className="w-25" alt="Logo" /> </span><br />
                        <span className="logo_title mt-5"> Login Dashboard</span>
                    </div>
                    <div className="card-body">
                        {openMobTab ?
                            <>
                                <div className="redirect-text" onClick={() => { setMobTab(false); setEntOtp(false); error.getMobNo = ""; error.loginMsg = " " }}>
                                    {/* Login With Email ? */}
                                    </div>


                                {error.loginMsg && <span className='error-message'>{error.loginMsg}</span>}

                                <div>
                                    <div className="input-group form-group">
                                        <div className="input-group-prepend">
                                            <span className="input-group-text"><i className="fa fa-mobile"></i></span>
                                        </div>
                                        <input type="number" className="form-control" name='mobileNo' value={getMobNo} onChange={(e) => handleChange(e)} placeholder="Mobile Number" />
                                    </div>
                                    {error.getMobNo && <span className='error-message'>{error.getMobNo}</span>}

                                    {/* <div className="input-group form-group">
                                                <div className="input-group-prepend">
                                                    <span className="input-group-text"><i className="fa fa-key"></i></span>
                                                </div>
                                                <input type="password" className="form-control" name='otp' placeholder="Enter OTP" value={getOtp} onChange={(e) => handleChange(e)} />
                                            </div>
                                            {error.password && <span className='error-message'>{error.password}</span>}
                                            <button className="btn float-right login_btn" onClick={() => handleGetOtp()} >Login</button> */}


                                    {openEntOtp ?
                                        <>
                                            <div className="input-group form-group">
                                                {/* <div className="input-group-prepend">
                                                    <span className="input-group-text"><i className="fa fa-key"></i></span>
                                                </div>
                                                 <input type="password" className="form-control" name='otp' placeholder="Enter OTP" value={getOtp} onChange={(e) => handleChange(e)} /> */}

                                                <div className='col text-center'>
                                                    <div className="otp-title" >Enter the OTP</div>
                                                    {
                                                        getOtp.map((data, index) => {
                                                            return (
                                                                <input
                                                                    className='otp-field'
                                                                    type="text"
                                                                    name="otp"
                                                                    maxLength="1"
                                                                    key={index}
                                                                    value={data}
                                                                    onChange={(e) => handleOtpChange(e.target, index)}
                                                                    onFocus={(e) => e.target.select()}
                                                                />
                                                            )
                                                        })
                                                    }
                                                </div>

                                            </div>
                                            {error.password && <span className='error-message'>{error.password}</span>}
                                            <button className="btn float-right login_btn" onClick={() => handleOtpLogin()} >Login</button>
                                        </> :
                                        <div>
                                            <button className="btn float-right login_btn" onClick={() => handleGetOtp()} >Get OTP</button>
                                        </div>

                                    }

                                </div>


                                <div className="redirect-text" ></div>
                            </>
                            :
                            <>
                                <div className="redirect-text" onClick={() => { setMobTab(true); error.loginMsg = " " }}>Login With Mobile Number ?</div>


                                {error.loginMsg && <span className='error-message'>{error.loginMsg}</span>}

                                <div>
                                    <div className="input-group form-group">
                                        <div className="input-group-prepend">
                                            <span className="input-group-text"><i className="fa fa-user"></i></span>
                                        </div>
                                        <input type="text" className="form-control" name='userName' value={userName} onChange={(e) => handleChange(e)} placeholder="Email" />
                                    </div>
                                    {error.userName && <span className='error-message'>{error.userName}</span>}
                                    <div className="input-group form-group">
                                        <div className="input-group-prepend">
                                            <span className="input-group-text"><i className="fa fa-key"></i></span>
                                        </div>
                                        <input type="password" className="form-control" name='password' placeholder="Password" value={password} onChange={(e) => handleChange(e)} />
                                    </div>
                                    {error.password && <span className='error-message'>{error.password}</span>}
                                </div>
                                <button className="btn float-right login_btn" onClick={() => handleLogin()} >Login</button>

                                {/* <div className="redirect-text" onClick={() => navigate('/sign_up')}>Don't have account Sign Up</div>
                                <div className="redirect-text" onClick={() => navigate('/reset_password')}>Reset Password</div> */}

                            </>

                        }
                    </div>
                </div>
            </div>
        </>
    )
}

export default SignIn
