export const testimonialData = [
    {
        image: 'https://images.pexels.com/photos/1222271/pexels-photo-1222271.jpeg?auto=compress&cs=tinysrgb&dpr=1&w=500',
        text: [
            'If there is any good travel agency in indore, it is standard journey, good service with good staff and you can also hire car for self driving with affordable price. Thank you TSj for making my trip memorable 😊',
        ],
        name: 'Vishal Singh'
    },
    {
        image: 'https://images.pexels.com/photos/1024311/pexels-photo-1024311.jpeg?auto=compress&cs=tinysrgb&dpr=3&h=750&w=1260',
        text: [
            'I have tacken vehicles from the standard journey it was glad to inform that rent was 6000 of white creta due to Happy customer I got free rental thanks the standard journey',
        ],
        name: 'Mr. Prince'
    },
    {
        image: 'https://images.pexels.com/photos/1036622/pexels-photo-1036622.jpeg?auto=compress&cs=tinysrgb&dpr=1&w=500',
        text: [
            'Its our first road trip and special thanks to the standard journey for making our journey comfortable and economical.'
        ],
        name: 'Dhruve Sharma'
    },
    {
        image: 'https://images.pexels.com/photos/1212984/pexels-photo-1212984.jpeg?auto=compress&cs=tinysrgb&dpr=1&w=500',
        text: [
            'Car rental and lease service was awesome. Staff member is too friendly and supportive. Even we were 2 hours late and they charged Zero for that. No cleaning charges. Awesome  car rental service in indore.',
        ],
        name: 'Hemant Singoriya'
    },
    {
        image: 'https://images.pexels.com/photos/1832959/pexels-photo-1832959.jpeg?auto=compress&cs=tinysrgb&dpr=1&w=500',
        text: [
            'One of the best agency in indore for rented car. I choose xcent for self drive. it gives good milage and comfort.',
        ],
        name: 'Yash Rajpal'
    }, {
        image: 'https://images.pexels.com/photos/718261/pexels-photo-718261.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940',
        text: [
            'Good Experience with Mr Bipin singh sir helpful nature excellent service provider proper staff Highly recommend Best service provider in Indore & bhopal',
        ],
        name: 'Maulik Palod'
    },
]