import axios from 'axios'
import React, { useState, useEffect } from 'react'
import PeriodFilter from '../miscellaneous/periodFilter'
// import './bookings.scss'

const ViewInvoices = () => {

    const [selectedFilter, setSelectedFilter] = useState('all')
    const [startDate, setStartDate] = useState('')
    const [endDate, setEndDate] = useState('')
    const [invoices, setInvoices] = useState([])

    useEffect(() => {
        listInvoice()
    }, [])

    const listInvoice = () => {
        let start = new Date();
        let end = new Date();
        if (selectedFilter === 'all') {
            //initailly setting 5 years range to get all invoices
            start.setFullYear(start.getFullYear() - 5);
        } else if (selectedFilter === 'last_month') {
            var date = new Date();
            start = new Date(date.getFullYear(), date.getMonth() - 1, 1);
            end = new Date(date.getFullYear(), date.getMonth(), 0);
        } else if (selectedFilter === 'this_month') {
            var date = new Date();
            start = new Date(date.getFullYear(), date.getMonth(), 1);
            end = new Date(date.getFullYear(), date.getMonth() + 1, 0);
        } else if (selectedFilter === 'custom') {
            start = new Date(startDate)
            end = new Date(endDate)
        }


        axios.get('listInvoice', { params: { startDate: start, endDate: end } })
            .then(res => {
                console.log(res.data)
                setInvoices(res.data)
            })
    }

    const handleDownload = (fileKey) => {
        window.open(`downloadInvoice/${fileKey}`, "_blank");
    }
    const handleApplyFilter = () => {
        listInvoice()
    }
    return (
        <div className='bookingsContainer'>
            <PeriodFilter
                selectedFilter={selectedFilter}
                startDate={startDate}
                endDate={endDate}
                setSelectedFilter={setSelectedFilter}
                setStartDate={setStartDate}
                setEndDate={setEndDate}
                handleApplyFilter={handleApplyFilter}
            />
            {
                invoices?.length ?
                    <div className="table-responsive-sm">
                        <table class="table ">
                            <thead>
                                <tr>
                                    <th >#</th>
                                    <th >Name</th>
                                    <th >Start Date</th>
                                    <th >End Date</th>
                                    <th >Vehicle</th>
                                    <th >Amount</th>
                                </tr>
                            </thead>
                            <tbody>
                                {invoices.map((item, index) =>
                                    <tr key={index}>
                                        <th scope="row">{index + 1}</th>
                                        <td>{item.name}</td>
                                        <td>{item.startDate?.substring(0, 10)}</td>
                                        <td>{item.endDate?.substring(0, 10)}</td>
                                        <td> <a className="download" onClick={() => handleDownload(item.Key)}>{item.Key}</a></td>
                                        <td>₹{item.totalAmount}</td>
                                    </tr>
                                )}
                            </tbody>
                        </table>
                    </div>
                    :
                    <div className="noBookings">No Invoices Found</div>
            }
        </div>
    )
}
export default ViewInvoices
