import React, { useState, useEffect } from 'react'

import './carComponent.scss'
import { formatDiscount } from '../../utils/helper'

const CarComponent = ({ data, index, forAdmin = false, handleButtonClick }) => {

    const [contentType, setContentType] = useState('desktop')

    useEffect(() => {
        handleResize();
        window.addEventListener('resize', handleResize);
    }, []);

    const handleResize = () => {
        if (window.screen.width < 481) {
            setContentType('mobile')
        } else {
            setContentType('desktop')
        }
    };

    const mobContent = <div>
        <div className="component-car-item">
            <div className="info">
                <div className="desc">
                    <div>
                        <h3>{data.name}</h3>
                    </div>
                    <img src={`/picture/${data.image}`} alt="" />
                    <div className="card-bottom-content">

                        <div className='car-details'>
                            <div className="">
                                {data.seater} Seater
                            </div>
                            <div className="">
                                {data.fuelType}
                            </div>
                            {!!data.offers?.length && data.offers.map((item, index) => <div key={index} className="">
                                {formatDiscount(item)}
                            </div>)}
                        </div>
                        <div className="price-book-ctr">
                            {!forAdmin && <div className="price-bar">
                                ₹ {data.price}
                            </div>}
                            <button onClick={() => handleButtonClick(index)} className='book'>{forAdmin ? 'Edit' : 'Book Now'}</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    const desktopContent = <div>
        <div className="component-car-item">
            <div className="info">
                <img src={`/picture/${data.image}`} alt="" />
                <div className="desc">
                    <div>
                        <h3>{data.name}</h3>
                    </div>
                    <div>
                        {data.seater} Seater
                    </div>
                    <div>
                        {data.fuelType}
                    </div>
                    {!!data.offers?.length && data.offers.map((item, index) => <div key={index} className="">
                        {formatDiscount(item)}
                    </div>)}
                </div>
            </div>
            <div className="price-book-ctr">
                {!forAdmin && <div className="price-bar">
                    ₹ {data.price}
                </div>}
                <button onClick={() => handleButtonClick(index)} className='book'>{forAdmin ? 'Edit' : 'Book Now'}</button>
            </div>
        </div>
    </div>

    return (
        contentType === 'desktop' ? desktopContent : mobContent
    )
}

export default CarComponent
