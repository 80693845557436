import React from 'react'
import Loader from "react-loader-spinner";
import './loader.scss'

const LoaderComp = () => {
    return (
        <div className='loader'>
            <Loader
                type="TailSpin"
                color="#83b422"
                height={100}
                width={100}
            />
        </div>
    )
}
export default LoaderComp
