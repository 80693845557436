import React, { useEffect, useState } from 'react'
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { formatDiscount } from '../../utils/helper';
import FileInput from '../fileInput';
import './carForm.scss';

const CarForm = ({ open, data, confirm, cancel, isNew }) => {
    const [modelName, setModalName] = useState('')
    const [fuelType, setFuelType] = useState('')
    const [seater, setSeater] = useState('')
    // const [basePrice, setBasePrice] = useState('')
    const [carImage, setCarImage] = useState('')
    // const [kms, setKms] = useState('')
    const [offers, setOffers] = useState([])
    //offer hooks
    const [name, setName] = useState('')
    const [type, setType] = useState('percent')
    const [value, setValue] = useState('')
    const [id, setId] = useState('')
    const [basePrice1, setBasePrice1] = useState('')
    const [basePrice2, setBasePrice2] = useState('')
    const [basePrice3, setBasePrice3] = useState('')
    const [extraKmCharge, setExtraKmCharge] = useState('')
    const [extraHourCharge, setExtraHourCharge] = useState('')


    useEffect(() => {
        updateData()
    }, [data])

    // useEffect(() => {

    // }, [offers])
    const handleSubmit = () => {
        const updatedData = {
            id: id,
            name: modelName,
            fuelType: fuelType,
            seater: seater,
            basePrice1,
            basePrice2,
            basePrice3,
            extraKmCharge,
            extraHourCharge,
            image: carImage,
            offers,
        }
        if (!isNew) {
            updatedData.id = data.id
            if (carImage.includes('/picture/')) {
                updatedData.image = carImage.split('/')[2]
            }
        }
        console.log('data', updatedData);
        confirm(updatedData);
        updateData();
    }

    const handleCancel = () => {
        updateData();
        cancel();
    }

    const updateData = () => {
        setModalName(data.name);
        setFuelType(data.fuelType);
        setSeater(data.seater);
        setBasePrice1(data.basePrice1);
        setBasePrice2(data.basePrice2);
        setBasePrice3(data.basePrice3);
        setExtraKmCharge(data.extraKmCharge)
        setExtraHourCharge(data.extraHourCharge)
        setCarImage(`/picture/${data.image}`)
        setOffers(data.offers)
        // setKms(data.kms)
        setId(data.id);
    }

    const handleAddNewDiscount = () => {
        if (name === '' || value === '') {
            return alert('please fill all fields')
        }
        let offersRef = offers
        let offer = { name, type, value }
        offersRef.push(offer)
        setOffers(offersRef)
        clearOfferSection()
    }

    const onImageChoose = (event) => {
        const finalFile = event.target.files[0];
        const reader = new FileReader();
        reader.readAsDataURL(finalFile);

        reader.addEventListener(
            'load',
            () => {
                setCarImage(reader.result)
            },
            false,
        );
    }

    const clearOfferSection = () => {
        setName('')
        setType('percent')
        setValue('')
    }

    const handleRemoveDiscount = (index) => {
        setOffers(offers.filter((item, i) => i !== index))
    }

    const getCarImage = carImage => {
        if (carImage) {
            if (carImage.split(";base64,")[1]) {
                return carImage;
            } else {
                return `/picture/${carImage}`;
            }
        } else {
            return '';
        }
    }

    return (
        <div>
            <Modal isOpen={open} toggle={() => cancel()} >
                <ModalHeader toggle={() => cancel()}>{isNew ? 'Add New Car' : 'Edit Car Details'}</ModalHeader>
                <ModalBody>
                    {/* <form> */}
                    <div className="form-group">
                        <label htmlFor="name">Model Name</label>
                        <input className="form-control" name='modelName' onChange={(e) => setModalName(e.target.value)} value={modelName} />
                    </div>
                    <div className="form-group">
                        <label htmlFor="fuelType">Fuel Type</label>
                        <div className="form-control">
                            <input type="radio" name="fuelType" onChange={(e) => setFuelType('Petrol')} value={fuelType} checked={fuelType === 'Petrol'} />&nbsp;
                            <label for="html">Petrol</label>&nbsp;&nbsp;&nbsp;
                            <input type="radio" name="fuelType" onChange={(e) => setFuelType('Diesel')} value={fuelType} checked={fuelType === 'Diesel'} />&nbsp;
                            <label for="html">Diesel</label><br />
                        </div>
                    </div>
                    <div className="form-group">
                        <label htmlFor="fuelType">Seater</label>
                        <div className="form-control">
                            <input type="radio" name="seater" onChange={() => setSeater('4')} value={seater} checked={seater === '4'} />&nbsp;
                            <label for="html">4</label>&nbsp;&nbsp;&nbsp;
                            <input type="radio" name="seater" onChange={() => setSeater('5')} value={seater} checked={seater === '5'} />&nbsp;
                            <label for="html">5</label>&nbsp;&nbsp;&nbsp;
                            <input type="radio" name="seater" onChange={() => setSeater('6')} value={seater} checked={seater === '6'} />&nbsp;
                            <label for="html">6</label>&nbsp;&nbsp;&nbsp;
                            <input type="radio" name="seater" onChange={() => setSeater('6+')} value={seater} checked={seater === '6+'} />&nbsp;
                            <label for="html">6+</label><br />
                        </div>
                    </div>
                    {/* <div className="form-group">
                            <label htmlFor="name">Kms</label>
                            <input className="form-control" name='basePrice' onChange={(e) => setKms(e.target.value)} value={kms} />
                        </div> */}

                    <div className="my-1 heading">Base Prices</div>
                    <div className="">
                        <div className="d-grid">
                            <label htmlFor="name">For 12Hrs/120Kms</label>
                            <input className="form-control" name='basePrice1' onChange={(e) => setBasePrice1(e.target.value)} value={basePrice1} />
                        </div>
                        <div className="d-grid">
                            <label htmlFor="name">For 18Hrs/185Kms</label>
                            <input className="form-control" name='basePrice2' onChange={(e) => setBasePrice2(e.target.value)} value={basePrice2} />
                        </div>
                        <div className="d-grid">
                            <label htmlFor="name">For 24Hrs/250Kms</label>
                            <input className="form-control" name='basePrice3' onChange={(e) => setBasePrice3(e.target.value)} value={basePrice3} />
                        </div>
                    </div>
                    <p className="my-1 heading ">Extra Charges</p>
                    <div className="d-flex justify-content-between">
                        <div className="d-grid mx-1">
                            <label htmlFor="name">Rate for Extra Hour</label>
                            <input className="form-control" name='extraHourCharge' onChange={(e) => setExtraHourCharge(e.target.value)} value={extraHourCharge} />
                        </div>
                        <div className="d-grid mx-1">
                            <label htmlFor="name">Rate for extra Km</label>
                            <input className="form-control" name='extraKmCharge' onChange={(e) => setExtraKmCharge(e.target.value)} value={extraKmCharge} />
                        </div>
                    </div>
                    <div className="offerrsContainer">
                        <div className='heading'>Applied Offers</div>
                        {offers?.length ? offers.map((item, index) => <div key={index} className="discountRow">
                            {formatDiscount(item)}
                            <i title={'remove this discount from this Vehicle'} className='fa fa-remove' onClick={() => handleRemoveDiscount(index)} />
                        </div>)
                            :
                            <div className="">No offers applied to this vehicle</div>
                        }
                        <div className="form-group">
                            <div className='heading'>Add New Offer</div>
                            <label>Name</label>
                            <input className="form-control" name='modelName' onChange={(e) => setName(e.target.value)} value={name} />
                            <label>Type</label>
                            <select className="form-control" onChange={(e) => setType(e.target.value)}>
                                <option value="percent">Percent(%)</option>
                                <option value="price">Price(₹)</option>
                            </select>
                            <label>Value</label>
                            <input type='number' className="form-control" name='modelName' onChange={(e) => setValue(e.target.value)} value={value} />
                            <button className='button-add' onClick={() => handleAddNewDiscount()}>Add</button>
                        </div>
                        {/* <div className="form-group">
                            <label htmlFor="name">Base Price</label>
                            <input type="number" className="form-control" name='basePrice' onChange={(e) => setBasePrice(e.target.value)} value={basePrice} />
                        </div>
                        <div className="form-group my-3">
                            <label>Car Image</label>
                            <FileInput imgSrc={getCarImage(carImage)} onFileChange={onImageChoose} />
                        </div> */}
                    </div>
                    <div className="form-group my-3">
                        <label>Car Image</label>
                        <FileInput imgSrc={carImage} onFileChange={onImageChoose} heading='Car Image' />
                    </div>
                    {/* </form> */}
                </ModalBody>
                <ModalFooter>
                    <Button color="primary" onClick={() => handleSubmit()}>Save</Button>{' '}
                    <Button color="secondary" onClick={() => handleCancel()}>Cancel</Button>
                </ModalFooter>
            </Modal>
        </div>
    );
}

export default CarForm;