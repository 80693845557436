import axios from 'axios';
import React, { useState } from 'react'
import { Button } from 'reactstrap';

const PaymentForm = () => {
    const [name, setName] = useState('')
    const [email, setEmail] = useState('')
    const [contact, setContact] = useState('')
    const [amount, setAmount] = useState('')

    const handleSubmit = () => {
        const data = {
            "order_id": Date.now().toString(),
            "order_amount": parseFloat(amount),
            "order_currency": "INR",
            "customer_details": {
                "customer_id": name.split(' ').join(''),
                "customer_email": email,
                "customer_phone": contact,
                "customer_name": name,
            },
            "order_meta": {
                "return_url": `https://thestandardjourney.com/payment_status?order_id={order_id}&order_token={order_token}`
            }
        }
        // for local host
        // https://cors-anywhere.herokuapp.com/corsdemo
        // const orderUrl = 'https://cors-anywhere.herokuapp.com/https://sandbox.cashfree.com/pg/orders';

        // for prod
        const orderUrl = '/orders';

        const options = {
            method: 'POST',
            url: orderUrl,
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json'
            },
            data: data
        };

        axios.request(options).then(function (response) {
            console.log(response.data);
            window.location.href = response.data.payment_link;
        }).catch(function (error) {
            console.error(error?.response);
            const message = error?.response?.data?.message?.split(':')[1]
            alert(message)
        });

    }


    return (
        <div className='container p-5'>
            <div className="form-group">
                <label htmlFor="name">Name</label>
                <input className="form-control" name='name' onChange={(e) => setName(e.target.value)} value={name} />
            </div>
            <div className="form-group mt-2">
                <label htmlFor="name">Email</label>
                <input type='email' className="form-control" name='email' onChange={(e) => setEmail(e.target.value)} value={email} />
            </div>
            <div className="form-group mt-2">
                <label htmlFor="name">Contact No.</label>
                <input type='number' className="form-control" name='contact' onChange={(e) => setContact(e.target.value)} value={contact} />
            </div>
            <div className="form-group mt-2">
                <label htmlFor="name">Amount</label>
                <input type='number' className="form-control" name='amount' onChange={(e) => setAmount(e.target.value)} value={amount} />
            </div>
            <div className="d-flex justify-content-around">
                <Button className='mt-4 ' onClick={() => handleSubmit()}>Proceed to Pay</Button>
            </div>

        </div>
    );
}

export default PaymentForm;