import React, { useState, useEffect, useContext } from 'react'
import './signIn.scss'
import { useNavigate } from 'react-router';

import logo from '../../assets/company_logo.jpeg'
import Header from '../header';
import { useCookies } from "react-cookie";

const SignUp = () => {

    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [mail, setMail] = useState('');
    const [contactNo, setContactNo] = useState();
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [aadharNo, setAadharNo] = useState('');
    const [drivingLicenseNo, setDrivingLicenseNo] = useState('');
    const [nextStep, setNextStep] = useState(false);
    const [verificationCode, setVerificationCode] = useState('');
    const [error, setError] = useState({});

    const navigate = useNavigate();

    const [cookies, setCookie] = useCookies();

    useEffect(() => {
        if(cookies && cookies.authorization ) {
            if(cookies.isAdmin === 'true'){
                navigate('/admin_dashboard')
            }else{
                navigate('/dashboard')
            }
        }
    }, []);

    const validateData = (data) => {
        let errors = {}
        if (data.firstName === '') {
            errors.firstName = "First Name can't be blank";
        }
        if (data.lastName === '') {
            errors.lastName = "Last Name can't be blank";
        }
        if (data.mail === '') {
            errors.mail = "Mail can't be blank";
        }
        if (data.contactNo === '') {
            errors.contactNo = "Contact No can't be blank";
        }
        if (data.password === '') {
            errors.password = "Password can't be blank";
        }
        if (data.confirmPassword === '') {
            errors.confirmPassword = "Confirm Password can't be blank";
        }
        if (data.password !== data.confirmPassword) {
            errors.confirmPassword = "Password & Confirm Password mismatched";
        }
        if (data.aadharNo === '' || data.drivingLicenseNo === '') {
            errors.aadharOrDriving = "Aadhar or Driving License required";
        }
        setError(errors)
        if (Object.keys(errors).length) {
            return false;
        } else {
            return true;
        }
    }

    const handleChange = (e) => {
        const name = e.target.name;
        const value = e.target.value;
        if (name === 'firstName') {
            setFirstName(value);
        } else if (name === 'lastName') {
            setLastName(value);
        }  else if (name === 'mail') {
            setMail(value);
        } else if (name === 'contactNo') {
            setContactNo(value);
        } else if (name === 'password') {
            setPassword(value);
        } else if (name === 'confirmPassword') {
            setConfirmPassword(value);
        }else if (name === 'verificationCode') {
            setVerificationCode(value);
        }else if (name === 'aadharNo') {
            setAadharNo(value);
        }else if (name === 'drivingLicenseNo') {
            setDrivingLicenseNo(value);
        }

        let errorRef = error;
        errorRef[name] = null;
        setError(errorRef);
    }

    const handleSignUp = () => {
        const data = {
            firstName,
            lastName,
            mail,
            contactNo,
            password,
            confirmPassword,
            aadharNo,
            drivingLicenseNo
        }
        if (validateData(data)) {
            fetch('/signup', {
                method: 'post',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(data),
                credentials: "same-origin"
            })
            .then(function(response) {
                if (response.status!==200) {
                  response.json().then(function(err) {
                      console.error(err);
                      setError({signUpMsg : err.message});
                  })
                } else {
                  response.json().then(function(object) {
                    setNextStep(true);
                  })
                }
            })
        }
    }

    const handleVerification = () => {
        if (verificationCode === '') {
            setError({verificationCode: "Verification code can't be blank"})
        }else{
            
            fetch('/verification', {
                method: 'post',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({"username": mail, "verificationCode" : verificationCode}),
                credentials: "same-origin"
            })
            .then(function(response) {
                if (response.status!==200) {
                  response.json().then(function(err) {
                    console.error(err);
                    setError({verifyMsg : err.message});
                  })
                } else {
                  response.json().then(function(object) {
                    navigate('/sign_in');
                  })
                }
            })
        }
    }

    return (
        <>
            <Header />
            <div className="container-fluid signin-container bg-dark">
                <div className="card card-login mx-auto text-center bg-dark">
                    <div className="card-header mx-auto bg-dark">
                        <span> <img src={logo} className="w-25" alt="Logo" /> </span><br />
                        <span className="logo_title mt-5"> Sign Up </span>
                    </div>
                    { !nextStep ? 
                        <div className="card-body">
                            {error.signUpMsg && <span className='error-message'>{error.signUpMsg}</span>}
                            <div>
                                <div className="input-group form-group">
                                    <div className="input-group-prepend">
                                        <span className="input-group-text"><i className="fa fa-user"></i></span>
                                    </div>
                                    <input type="text" className="form-control" name="firstName" value={firstName} onChange={(e) => handleChange(e)} placeholder="First Name" />
                                </div>
                                {error.firstName && <span className='error-message'>{error.firstName}</span>}
                                <div className="input-group form-group">
                                    <div className="input-group-prepend">
                                        <span className="input-group-text"><i className="fa fa-user"></i></span>
                                    </div>
                                    <input type="text" className="form-control" name="lastName" value={lastName} onChange={(e) => handleChange(e)} placeholder="Last Name" />
                                </div>
                                {error.lastName && <span className='error-message'>{error.lastName}</span>}
                                <div className="input-group form-group">
                                    <div className="input-group-prepend">
                                        <span className="input-group-text"><i className="fa fa-envelope"></i></span>
                                    </div>
                                    <input type="mail" className="form-control" name='mail' value={mail} onChange={(e) => handleChange(e)} placeholder="Email" />
                                </div>
                                {error.mail && <span className='error-message'>{error.mail}</span>}
                                <div className="input-group form-group">
                                    <div className="input-group-prepend">
                                        <span className="input-group-text"><i className="fa fa-phone-square"></i></span>
                                    </div>
                                    <input type="number" className="form-control" name='contactNo' value={contactNo} onChange={(e) => handleChange(e)} placeholder="Contact No" />
                                </div>
                                {error.contactNo && <span className='error-message'>{error.contactNo}</span>}
                                <div className="input-group form-group">
                                    <div className="input-group-prepend">
                                        <span className="input-group-text"><i className="fa fa-id-card"></i></span>
                                    </div>
                                    <input type="number" className="form-control" name='aadharNo' value={aadharNo} onChange={(e) => handleChange(e)} placeholder="Aadhar No" />
                                </div>
                                <div className="input-group form-group">
                                    <div className="input-group-prepend">
                                        <span className="input-group-text"><i className="fa fa-id-badge"></i></span>
                                    </div>
                                    <input type="text" className="form-control" name='drivingLicenseNo' value={drivingLicenseNo} onChange={(e) => handleChange(e)} placeholder="Driving License No" />
                                </div>
                                {error.aadharOrDriving && <span className='error-message'>{error.aadharOrDriving}</span>}
                                <div className="input-group form-group">
                                    <div className="input-group-prepend">
                                        <span className="input-group-text"><i className="fa fa-key"></i></span>
                                    </div>
                                    <input type="password" className="form-control" placeholder="Password" name='password' value={password} onChange={(e) => handleChange(e)} />
                                </div>
                                {error.password && <span className='error-message'>{error.password}</span>}
                                <div className="input-group form-group">
                                    <div className="input-group-prepend">
                                        <span className="input-group-text"><i className="fa fa-key"></i></span>
                                    </div>
                                    <input type="password" className="form-control" placeholder="Confirm Password" name='confirmPassword' value={confirmPassword} onChange={(e) => handleChange(e)} />
                                </div>
                                {error.confirmPassword && <span className='error-message'>{error.confirmPassword}</span>}
                            </div>
                            <button className="btn float-right login_btn" onClick={() => handleSignUp()} >Sign Up</button>
                            <div className="redirect-text" onClick={() => navigate('/sign_in')}>Already have account Sign In</div>
                        </div>
                        :
                        <div className="card-body">
                            {error.verifyMsg && <span className='error-message'>{error.verifyMsg}</span>}
                            <div>
                                <div className="input-group form-group">
                                    <div className="input-group-prepend">
                                        <span className="input-group-text"><i className="fa fa-envelope"></i></span>
                                    </div>
                                    <input type="mail" className="form-control" name='mail' value={mail} onChange={(e) => handleChange(e)} placeholder="Email" />
                                </div>
                                <div className="input-group form-group">
                                    <div className="input-group-prepend">
                                        <span className="input-group-text"><i className="fa fa-key"></i></span>
                                    </div>
                                    <input type="password" className="form-control" placeholder="Email verification Code" name='verificationCode' value={verificationCode} onChange={(e) => handleChange(e)} />
                                </div>
                                {error.verificationCode && <span className='error-message'>{error.verificationCode}</span>}
                            </div>
                            <button className="btn float-right login_btn" onClick={() => handleVerification()} >Verify</button>
                        </div> 
                    }
                </div>
            </div>
        </>
    )
}

export default SignUp
