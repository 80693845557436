import React from 'react';
import Footer from '../footer';
import Header from '../header';
import PaymentForm from './paymentForm';

const PayNow = () => {
    return (
        <div>
            <Header />
            <PaymentForm />
            <Footer />
        </div>
    )
};

export default PayNow;
