import axios from "axios";
import React from "react";
import { useState } from "react";
import { useEffect } from "react";

export const Userdetail = () => {
    const [usersList, setUserList] = useState([]);

    useEffect(() => {
        fetch('/getUsers', {
            method: 'post',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            // body: JSON.stringify({ "phoneNumber": getMobNo }),
            credentials: "same-origin"
        })
            .then(function (response) {
                if (response.status !== 200) {
                    response.json().then(function (err) {
                        //setError({ loginMsg: "OTP not received" })
                    })
                } else {
                    response.json().then(function (res) {
                        console.log("users list", res);
                        setUserList(res?.data);


                    })
                }
            });
    }, [usersList]);

    const editStatus =(phoneNumber, isActive, customerId)=>{
        console.log(phoneNumber, isActive, customerId)
        fetch('/blockUnblockUser', {
            method: 'post',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({ "phoneNumber": phoneNumber ,"isActive" : isActive ? false : true,"customerId":customerId }),
            credentials: "same-origin"
        })
            .then(function (response) {
                if (response.status !== 200) {
                    response.json().then(function (err) {
                        //setError({ loginMsg: "OTP not received" })
                    })
                } else {
                    response.json().then(function (res) {
                        console.log("Working")


                    })
                }
            });
    }

    return (
        <div className="table-responsive">
            <table class="table">
                <thead class="thead-dark">
                    <tr>
                        <th scope="col">S No</th>
                        <th scope="col">Name</th>
                        <th scope="col">Mobile</th>
                        <th scope="col">Email</th>
                        <th scope="col">Aadhar No</th>
                        <th scope="col">Driving License</th>
                        <th scope="col">Status</th>
                        <th scope="col">Action</th>
                    </tr>
                </thead>
                <tbody>
                    {usersList.map((user) => {
                        return (
                            <tr id={user.id}>
                                <th scope="row">{user.id}</th>
                                <td>{user.givenName ? user.givenName : "-" }</td>
                                <td>{user.phoneNumber ? user.phoneNumber : "-" }</td>
                                <td>-</td>
                                <td>{user.aadharNo ? user.aadharNo : "-"}</td>
                                <td>{user.drivingLicenseNo ?user.drivingLicenseNo : "-" }</td>
                                <td>
                                    {user.isActive ? 
                                    <div  class="font-weight-normal text-primary ">Active</div> 
                                    
                                    : 
                                    <div  class="font-weight-normal text-danger">Blocked
                                    </div>
                                    }
                                </td>

                                <td ><button type="button" class="btn btn-danger" onClick={()=>editStatus(user.phoneNumber,user.isActive,user.id)}>Edit</button></td>
                            </tr>
                        )
                    })}
                    
                </tbody>
            </table>


        </div>

    );
}
