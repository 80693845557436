export const galleryImages = [
    {
        name: 'Creta',
        image: require('../assets/innerterrace.jpeg')
    },
    {
        name: 'scross',
        image: require('../assets/scross_blue.jpeg')
    },
    {
        name: 'scross',
        image: require('../assets/scross_blue_leftside.jpeg')
    },
    {
        name: 'creta',
        image: require('../assets/seatdown_blue.jpeg')
    },
    {
        name: 'creta',
        image: require('../assets/car_dashboard.jpeg')
    },
    {
        name: 'scross',
        image: require('../assets/scross_blue_rear.jpeg')
    },
    {
        name: 'nexon',
        image: require('../assets/tata_nexon.jpeg')
    },
    {
       name: 'altroz',
       image: require('../assets/tata_altroz.jpeg')
   },
   {
    name: 'puch',
    image: require('../assets/tata_punch.png')
},
   {
    name: 'tiago',
    image: require('../assets/tata_tiago.jpeg')
},
]