import React from "react";
import { useState } from "react";
import "./uploadDoc.css";
import { useCookies } from "react-cookie";
import { useEffect } from "react";


const UploadDoc = (props) => {

    const [addharFron, setAdharFron] = useState();
    const [addharBack, setSdharBac] = useState();
    const [getLic, setLic] = useState();
    const [getSel, setSel] = useState();


    // User data from cookies
    const [customerID, setCustID] = useState({});
    const [cookies, setCookie] = useCookies();

    useEffect(() => {

        if (cookies && cookies.userData) {
            setCustID(cookies.userData.username);
            console.log("Customer id ", customerID)
        }

    }, [])

 


    const uploadImage = async (e) => {
        console.log(e.target.name);

        const file = e.target.files[0];
        let type = e.target.name;

        const base64 = await convertBase64(file);


        // // setBase(base64);
        if (type === "aadharFront") {
            setAdharFron(base64);
        }
        else if (type === "aadharBack") {
            setSdharBac(base64);
        }
        else if (type === "license") {
            setLic(base64);
        }
        else if (type === "selfie") {
            setSel(base64);
        }
    }

    const convertBase64 = (file) => {
        return new Promise((resolve, reject) => {
            const fileReader = new FileReader();
            fileReader.readAsDataURL(file);

            fileReader.onload = () => {
                return resolve(fileReader.result);
            };

            fileReader.onerror = (error) => {
                return reject(error);
            }
        })
    }

    const handleUploadImageBase = (e) => {
        let type = e.target.name;
        console.log(type);
        let image;
        if (type === "aadharFront") {
            image = addharFron;
            console.log(image, "1")
        }
        else if (type === "aadharBack") {
            image = addharBack;
            console.log(addharBack, "2")
        }
        else if (type === "license") {
            image = getLic;
        }
        else if (type === "selfie") {
            image = getSel;
        }
        fetch('/uploadDocs', {
            method: 'post',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({ "type": type, "image": image, "id": customerID }),
            credentials: "same-origin"
        })
            .then(function (response) {
                if (response.status !== 200) {
                    response.json().then(function (err) {
                        //setError({ loginMsg: "OTP not received" })
                    })
                } else {
                    response.json().then(function (res) {
                        alert("Uploaded");

                    })
                }
            });
    }

    console.log(props.setButton,"Check")

    useEffect(()=>{
        
            console.log(addharFron !== null || addharBack !== null || getLic !==null || getSel !== null)
            props.setButton(!props.buttonDisable)
        
    },[getSel])

    return (
        <div className="upload-container p-2">
            <p className="text-primary" style={{display: "flex"}}><p className="text-danger">*</p>Aadhar Front</p><input type='file' name="aadharFront" onChange={(e) => uploadImage(e)} /> <button className="btn btn-info mt-2 mb-2" name="aadharFront" onClick={e => handleUploadImageBase(e)}>Upload</button>
            <p className="text-primary" style={{display: "flex"}}><p className="text-danger">*</p>Aadhar Back</p><input type='file' name="aadharBack" onChange={(e) => uploadImage(e)} /><button className="btn btn-info mt-2 mb-2" name="aadharBack" onClick={e => handleUploadImageBase(e)}>Upload</button>
            <p className="text-primary" style={{display: "flex"}}><p className="text-danger">*</p>Driving Licence</p> <input type='file' name="license" onChange={(e) => uploadImage(e)} /><button className="btn btn-info mt-2 mb-2" name="license" onClick={e => handleUploadImageBase(e)}>Upload</button>
            <p className="text-primary" style={{display: "flex"}}><p className="text-danger">*</p>Profile Image</p><input type='file' name="selfie" onChange={(e) => uploadImage(e)} /><button className="btn btn-info mt-2 mb-2" name="selfie" onClick={e => handleUploadImageBase(e)}>Upload</button>

        </div>
    )
}

export default UploadDoc;