import React, { useEffect, useState } from 'react'
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';

const EditModal = ({profileData , open, data, confirm, cancel }) => {
    const [name, setName] = useState(data.name)
    const [email, setEmail] = useState(data.email)
    const [contact, setContact] = useState(data.phone)
    const [aadhar, setAadhar] = useState(data.aadhar)
    const [license, setLicense] = useState(data.license)


    const customer_id = profileData.username;
    console.log(customer_id,"Checkkk")
    useEffect(() => {
       updateData()
    }, [data])

    console.log(name,"name")
    const handleSubmit = (e) => {
        
        e.preventDefault();
        fetch('/editUser', {
            method: 'post',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({"givenName": name , "phoneNumber" : contact , "email" : email , "aadharNo" : aadhar , "drivingLicenseNo" : license  ,"customerId" :  customer_id}),
            credentials: "same-origin"
        })
        .then(function(response) {
            if (response.status!==200) {
              response.json().then(function(err) {
                alert("Failed to update")
                console.error(err);
               
              })
            } else {
              response.json().then(function(object) {
                alert("Profile is updated")
              })
            }
        });
        confirm();
    }

    const handleCancel = () => {
        updateData();
        cancel();
    }

    const updateData = () => {
        setName(data.name);
        setEmail(data.email);
        setContact(data.phone);
        setAadhar(data.aadhar);
        setLicense(data.license);
    }
    return (
        <div>
            <Modal isOpen={open} toggle={() => cancel()} >
                <ModalHeader toggle={() => cancel()}>Edit Profile</ModalHeader>
                <ModalBody>
                    <form>
                        <div className="form-group">
                            <label htmlFor="name">Name</label>
                            <input className="form-control" name='name' onChange={(e) => setName(e.target.value)} value={name} />
                        </div>
                        <div className="form-group">
                            <label htmlFor="email">Email address</label>
                            <input type="email" className="form-control" name="email" onChange={(e) => setEmail(e.target.value)} value={email} />
                        </div>
                        <div className="form-group">
                            <label htmlFor="contact">Contact No.</label>
                            <input className="form-control" name="contact" onChange={(e) => setContact(e.target.value)} value={contact} />
                        </div>
                        <div className="form-group">
                            <label htmlFor="contact">Aadhar No.</label>
                            <input className="form-control" name="aadhar" onChange={(e) => setAadhar(e.target.value)} value={aadhar} />
                        </div>
                        <div className="form-group">
                            <label htmlFor="contact">Driving License No.</label>
                            <input className="form-control" name="license" onChange={(e) => setLicense(e.target.value)} value={license} />
                        </div>
                    </form>
                </ModalBody>
                <ModalFooter>
                    <Button color="primary" onClick={(e) => handleSubmit(e)}>Save</Button>{' '}
                    <Button color="secondary" onClick={() => handleCancel()}>Cancel</Button>
                </ModalFooter>
            </Modal>
        </div>
    );
}

export default EditModal;
