import React from 'react'
import { tncData } from '../../utilsData/tncData'
import Header from '../header'
import './tnc.scss'

const TncPage = () => {
    return (
        <>
            <Header />
            <div className='container'>
                {tncData}
            </div>
        </>
    )
}

export default TncPage
