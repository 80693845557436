export const packageCardsData = [
    {
        id: '1',
        image: require('../assets/5-Days-Goa-Tour-small.png').default,
        title: 'Goa Tour – 4 Days',
        description: '03 Nights / 04 Days\nPlaces Covered - Goa',
        rate: '25,000',
        category: ['tourAndTravel', 'taxi']
    },
    {
        id: '2',
        image: require('../assets/delhi-tourist-places-small.png').default,
        title: '15 Days Tour in India',
        description: '14 Nights / 15 Days\nPlaces Covered - Delhi » Agra » Jaipur » Jodhpur » Udaipur » Goa » Mumbai',
        rate: '42,599',
        category: ['tourAndTravel']
    },
    {
        id: '3',
        image: require('../assets/jaipur-tours-small.png').default,
        title: '7 Days Tour in India',
        description: '6 Nights / 7 Days\nPlaces Covered - Delhi » Agra » Jaipur » Khajuraho » Varanasi',
        rate: '24,633',
        category: ['taxi']
    },
    {
        id: '4',
        image: require('../assets/varanasi-tour-small.png').default,
        title: '9 Day Tour in India',
        description: '04 Nights / 05 Days\nPlaces Covered - Goa » North Goa » South Goa',
        rate: '38,999',
        category: ['tourAndTravel', 'taxi']
    },
    {
        id: '5',
        image: require('../assets/Yoga-Tours-in-India-small.png').default,
        title: 'Yoga Tours in India',
        description: '8 Nights / 9 Days\nPlaces Covered - Delhi » Uttarkashi » Rishikesh » Haridwar',
        rate: '35,999',
        category: ['tourAndTravel', 'taxi']
    },
]