import React from 'react'

const AboutUs = () => {
    return (
        <div>
            About Us Page Content
        </div>
    )
}

export default AboutUs
