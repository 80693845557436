import React, { useEffect, useState } from 'react'
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import FileInput from '../fileInput';
import './occupancyForm.scss';

const TourCardForm = ({ open, data, confirm, cancel, isEdit }) => {

    const [title, setTitle] = useState('')
    const [description, setDescription] = useState('')
    const [rate, setRate] = useState('')
    const [image, setImage] = useState('')
    const [available, setAvailable] = useState('')

    useEffect(() => {
        updateData()
    }, [data])

    const handleSubmit = () => {
        const updatedData = {
            title,
            description,
            price: rate,
            image,
            available
        }
        if (isEdit) {
            updatedData.id = data.id
            if (image.includes('/picture/')) {
                updatedData.image = image.split('/')[2]
            }
        }
        console.log(updatedData);
        confirm(updatedData);
    }

    const handleCancel = () => {
        updateData();
        cancel();
    }

    const updateData = () => {
        setTitle(data.title);
        setDescription(data.description);
        setRate(data.price);
        setImage(`/picture/${data.image}`);
        setAvailable(data.available)
    }

    const onImageChoose = (event) => {
        const finalFile = event.target.files[0];
        const reader = new FileReader();
        reader.readAsDataURL(finalFile);

        reader.addEventListener(
            'load',
            () => {
                setImage(reader.result)
            },
            false,
        );
    }

    const onChange = (event) => {
        setAvailable(event.target.value)

    }

    return (
        <div>
            <Modal isOpen={open} toggle={() => cancel()} >
                <ModalHeader toggle={() => cancel()}>Tour Package</ModalHeader>
                <ModalBody>
                    <form>
                        <div className="form-group">
                            <label htmlFor="name">Title</label>
                            <input className="form-control" name='modelName' onChange={(e) => setTitle(e.target.value)} value={title} />
                        </div>
                        <div className="form-group">
                            <label htmlFor="name">Description</label>
                            <textarea className="form-control" name='basePrice' onChange={(e) => setDescription(e.target.value)} value={description} />
                        </div>
                        <div className="form-group">
                            <label htmlFor="name">Price</label>
                            <input className="form-control" name='basePrice' onChange={(e) => setRate(e.target.value)} value={rate} />
                        </div>
                        <div className="form-group">
                            <label>Available</label>
                            <div className="form-group"  >
                                <input
                                    className="form-control"
                                    value={available}
                                    onChange={(e) => onChange(e)}
                                />
                            </div>
                            {/* <div className="form-group" >
                                <input type="checkbox"
                                    value="taxi"
                                    checked={category.includes('taxi')}
                                    onChange={(e) => onChange(e)}
                                />
                                &nbsp;
                                <label>Taxi Services</label>
                            </div> */}
                        </div>
                        <div className="form-group my-3">
                            <label>Image</label>
                            <FileInput imgSrc={image} onFileChange={onImageChoose} heading='Image' />
                        </div>
                    </form>
                </ModalBody>
                <ModalFooter>
                    <Button color="primary" onClick={() => handleSubmit()}>Save</Button>{' '}
                    <Button color="secondary" onClick={() => handleCancel()}>Cancel</Button>
                </ModalFooter>
            </Modal>
        </div>
    );
}

export default TourCardForm;