import React, { useState, useEffect } from 'react'
import { useLocation } from 'react-router-dom';

import './chooseCar.scss'
import LeftContainer from './leftContainer'
import RightContainer from './rightContainer'
import Loader from '../miscellaneous/loader'
import CapsuleButtonGroup from '../miscellaneous/capsuleButtonGroup'
import { kmsData, serviceTypeData } from '../../utilsData/priceFactorData'
import Footer from '../footer'
import Header from '../header'
import PackageCard from '../packageCard'
import axios from 'axios'
import PackageForm from './packageForm'
import { useCookies } from "react-cookie";
import PackageDetailsContainer from './packageDetailsContainer'
import { useNavigate } from 'react-router';
import LoginModal from '../loginModal'

const ChooseCar = () => {
    const [seaterFilter, setSeaterFilter] = useState([])
    const [fuelTypeFilter, setFuelTypeFilter] = useState([])
    const [showLoader, setShowLoader] = useState(false)
    const [selectedServiceType, setSelectedServiceType] = useState(0)
    const [taxis, setTaxis] = useState([])
    const [tours, setTours] = useState([])
    const [packageDetails, setPackageDetails] = useState({})
    const [openPackageForm, setOpenPackageForm] = useState(-1)
    const [cookies, setCookie] = useCookies()
    const [userData, setUserData] = useState()
    const [openDetailsContainer, setOpenDetailsContainer] = useState(false)
    const [selectedPackageIndex, setSelectedPackageindex] = useState(-1)
    const [openLoginModal, setOpenLoginModal] = useState(false)
    const [distance, setDistance] = useState(120)
    const [time, setTime] = useState(12)

    const location = useLocation();

    useEffect(() => {
        axios.get('taxis')
            .then(res => setTaxis(res.data))
        axios.get('tours')
            .then(res => setTours(res.data))
        if (cookies && cookies.authorization) {
            setUserData(cookies.userData);
        }

        setSelectedServiceType(+location?.state?.tab || 0)
    }, [])


    const navigate = useNavigate();

    const handleSeaterFilter = (val) => {
        setShowLoader(true)
        let seaterFilterRef = seaterFilter;
        if (seaterFilterRef.includes(val)) {
            seaterFilterRef = seaterFilter.filter(i => i !== val)
        } else {
            seaterFilterRef.push(val)
        }
        setSeaterFilter(seaterFilterRef)
        setTimeout(() => { setShowLoader(false) }, 500)
    }

    const handleFuelTypeFilter = (val) => {
        setShowLoader(true)
        let fuelTypeFilterRef = fuelTypeFilter;
        if (fuelTypeFilterRef.includes(val)) {
            fuelTypeFilterRef = fuelTypeFilter.filter(i => i !== val)
        } else {
            fuelTypeFilterRef.push(val)
        }
        setFuelTypeFilter(fuelTypeFilterRef);
        setTimeout(() => { setShowLoader(false) }, 500)
    }

    const handleServiceTypeSelect = (index) => {
        setSelectedServiceType(index)
    }

    const handleCancel = () => {
        setOpenPackageForm(-1)
        setPackageDetails({})
    }

    const handleSubmit = () => {
        setSelectedPackageindex(openPackageForm)
        setOpenPackageForm(-1)
        setOpenDetailsContainer(true)
    }
    const handlePackageBookNow = (index) => {
        if (cookies && cookies.authorization) {
            setOpenPackageForm(index)
        } else {
            setOpenLoginModal(true)
        }
    }

    const handleLogin = () => {
        setCookie('redirectedFromBooking', true)
        navigate('/sign_in')
    }
    return (
        <>
            <Header />
            <div className='choose-car'>
                {showLoader &&
                    <Loader />}
                <div className={`row ${showLoader && 'disabled'}`}>
                    <CapsuleButtonGroup data={serviceTypeData} selected={selectedServiceType} handleButtonClick={handleServiceTypeSelect} />
                    {selectedServiceType === 0 ?
                        <div className='wrapperContainer'>
                            <div className={'col-md-3 left-contaner'}>
                                <LeftContainer
                                    seaterFilter={seaterFilter}
                                    setSeaterFilter={setSeaterFilter}
                                    fuelTypeFilter={fuelTypeFilter}
                                    setFuelTypeFilter={setFuelTypeFilter}
                                    handleSeaterFilter={handleSeaterFilter}
                                    handleFuelTypeFilter={handleFuelTypeFilter}
                                    distance={distance}
                                    setDistance={setDistance}
                                    time={time}
                                    setTime={setTime}
                                />
                            </div>
                            <div className='col right-continer'>
                                {/* <CapsuleButtonGroup data={kmsData} selected={selectedKms} handleButtonClick={handleKmSelect} /> */}
                                <RightContainer
                                    distance={distance}
                                    time={time}
                                    seaterFilter={seaterFilter}
                                    fuelTypeFilter={fuelTypeFilter}
                                    setDistance={setDistance}
                                    setTime={setTime}
                                />
                            </div>
                        </div>
                        : <div className="packageCards">
                            {
                                selectedServiceType === 1 ? tours.map((item, index) => <PackageCard data={item} buttonText='Book Now' onButtonClick={() => handlePackageBookNow(index)} />)
                                    : taxis.map((item, index) => <PackageCard data={item} buttonText='Book Now' onButtonClick={() => handlePackageBookNow(index)} />)
                            }
                            <PackageForm open={openPackageForm !== -1} selectedPackageData={selectedServiceType === 1 ? tours[openPackageForm] : taxis[openPackageForm]} handleCancel={() => handleCancel()} handleSubmit={() => handleSubmit()} packageDetails={packageDetails} setPackageDetails={setPackageDetails} userData={userData} />
                            <PackageDetailsContainer
                                open={openDetailsContainer}
                                selectedPackageData={selectedServiceType === 1 ? tours[selectedPackageIndex] : taxis[selectedPackageIndex]}
                                packageDetails={packageDetails}
                                handleCancel={() => setOpenDetailsContainer(false)}
                                packageType={selectedServiceType === 1 ? 'tour' : 'taxi'}
                            />
                            <LoginModal open={openLoginModal} handleCancel={() => setOpenLoginModal(false)} handleLogin={() => handleLogin()} />
                        </div>
                    }
                </div>
            </div>
            <Footer />
        </>
    )
}

export default ChooseCar
