import React from 'react'
import { Button, Modal, ModalBody, ModalFooter } from 'reactstrap';

const LoginModal = ({ open, handleCancel, handleLogin }) => {
    return (
        <div>
            <Modal isOpen={open} toggle={() => handleCancel()} >
                <ModalBody>
                    Please login to Continue...
                </ModalBody>
                <ModalFooter>
                    <Button color="primary" onClick={() => handleLogin()}>Login</Button>{' '}
                    <Button color="secondary" onClick={() => handleCancel()}>Cancel</Button>
                </ModalFooter>
            </Modal>
        </div>
    )
}

export default LoginModal