import React from 'react'

const ExpenseTable = ({ expenses, handleDeleteExpense }) => {
    return (
        <div>
            <table className="table table-bordered my-5">
                <thead>
                    <tr>
                        <th scope="col">S No</th>
                        <th scope="col">Booking</th>
                        <th scope="col">Quantity</th>
                        <th scope="col">Rate</th>
                        <th scope="col">Amount</th>
                    </tr>
                </thead>
                <tbody>
                    {expenses.map((expense, index) => {
                        return (
                            <tr>
                                <th scope="row">{expense.sNo}</th>
                                <td>{expense.booking}</td>
                                <td>{expense.quantity}</td>
                                <td>{expense.rate}</td>
                                <td>{expense.amount}</td>
                                <td>
                                    <i
                                        style={{ cursor: "pointer" }}
                                        className="fa fa-trash"
                                        onClick={() => handleDeleteExpense(index)}
                                    />
                                </td>
                            </tr>
                        )
                    })}
                </tbody>
            </table>
        </div>
    )
}

export default ExpenseTable
