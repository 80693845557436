import React, { useState } from 'react'
import EnquiryForm from '../../enquiryForm'

import './enquiry.scss'

const EnquiryButton = () => {
    const [openForm, setOpenForm] = useState(false)
    return (
        <>
        <div className='enquiry-component' onClick={() => setOpenForm(true)}>
            Send Enquiry&nbsp;<i className="fa fa-question-circle" />
        </div>
        <EnquiryForm open={openForm} handleCancel={() =>setOpenForm(false)}/>
        </>
    )
}

export default EnquiryButton
