import React from 'react'

import './capsuleButtonGroup.scss'

const CapsuleButtonGroup = ({data, selected, handleButtonClick}) => {
    return (
        <div className="capsule-button-group">
            {data.map((item, index) =>
                <div  key={index}
                className={`button ${index === 0 && 
                    'first-button'} 
                    ${index === data.length -1 && 'last-button'}
                    ${index === selected && 'active'}
                    `}
                    onClick={()=> handleButtonClick(index)}
                    >
                        {item.name}
                        </div>
                )}
        </div>
    )
}

export default CapsuleButtonGroup
