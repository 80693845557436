import React from "react";
import { Navigate, Route } from "react-router-dom";
import { useCookies } from "react-cookie";

function ProtectedRoute({ children }) {
  const [cookies, setCookie] = useCookies();
  return cookies.authorization ? children : <Navigate to="/" />;
}

function AdminRoute({ children }) {
    const [cookies, setCookie] = useCookies();
    return (cookies.authorization && (cookies.isAdmin === 'true')) ? children : <Navigate to="/" />;
  }

export { ProtectedRoute, AdminRoute };
