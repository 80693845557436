import React from 'react'
import './packageCard.scss'

const PackageCard = ({ data: { title, description, image, price, index }, buttonText, onButtonClick }) => {

    return (
        <div className='packageCardContainer'>
            <img src={`/picture/${image}`} />
            <div className="title">{title}</div>
            <div className='desc'>{description}</div>
            <div className='priceButtonContainer'>
                <div className="price">₹{price}</div>
                <div className='button' onClick={onButtonClick}>{buttonText}</div>
            </div>
        </div>
    )
}

export default PackageCard
