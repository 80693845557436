import React, { useState } from 'react'

const ExpenseDetailsInput = ({ addExpenses }) => {

    const [sNo, setSNo] = useState('')
    const [booking, setBooking] = useState('')
    const [quantity, setQuantity] = useState('')
    const [rate, setRate] = useState('')
    const [amount, setAmount] = useState('')

    const handleSave = () => {
        const data = {
            sNo,
            booking,
            quantity,
            rate,
            amount
        }
        addExpenses(data)
    }

    return (
        <div>
            <div className="row">
                <div className="col-md-2">
                    S No.
                    <input
                        type='number'
                        className="form-control"
                        placeholder="S No"
                        value={sNo}
                        onChange={(e) => setSNo(e.target.value)}
                    />
                </div>
                <div className="col-md">
                    Booking
                    <input
                        className="form-control"
                        placeholder="Booking"
                        value={booking}
                        onChange={(e) => setBooking(e.target.value)}
                    />
                </div>
            </div>
            <div className="row">
                <div className="col-md">
                    Quantity
                    <input
                        type='number'
                        className="form-control"
                        placeholder="Quantity"
                        value={quantity}
                        onChange={(e) => setQuantity(e.target.value)}
                    />
                </div>
                <div className="col-md">
                    Rate
                    <input
                        className="form-control"
                        placeholder="Rate"
                        value={rate}
                        onChange={(e) => setRate(e.target.value)}
                    />
                </div>
                <div className="col-md">
                    Amount
                    <input
                    type='number'
                        className="form-control"
                        placeholder="Amount"
                        value={amount}
                        onChange={(e) => setAmount(e.target.value)}
                    />
                </div>
            </div>
            <button onClick={() => handleSave()}>
                Save Expense
            </button>
        </div>
    )
}

export default ExpenseDetailsInput
