import React, { useState, useEffect, useContext } from 'react'
import './signIn.scss'
import { useNavigate } from 'react-router';
import logo from '../../assets/company_logo.jpeg'
import Header from '../header';
import { useCookies } from "react-cookie";

const ResetPassword = () => {

    const [userName, setUserName] = useState('');
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [nextStep, setNextStep] = useState(false);
    const [verificationCode, setVerificationCode] = useState('');
    const [error, setError] = useState({});

    const [cookies, setCookie] = useCookies();

    const navigate = useNavigate()

    useEffect(() => {
        if( cookies && cookies.authorization ) {
            if(cookies.isAdmin === 'true'){
                navigate('/admin_dashboard')
            }else{
                navigate('/dashboard')
            }
        }
    }, [])

    const validateUseName = (data) => {
        let errors = {}
        if (data.userName === '') {
            errors.userName = "Email can't be blank";
        }
        setError(errors)
        if (Object.keys(errors).length) {
            return false;
        } else {
            return true;
        }
    }

    const validateData = (data) => {
        let errors = {}
        if (data.userName === '') {
            errors.userName = "Email can't be blank";
        }
        if (data.password === '') {
            errors.password = "Password can't be blank";
        }
        if (data.confirmPassword === '') {
            errors.confirmPassword = "Confirm Password can't be blank";
        }
        if (data.password !== data.confirmPassword) {
            errors.confirmPassword = "Password & Confirm Password mismatched";
        }
        if (data.verificationCode === '') {
            errors.verificationCode = "Verification Code can't be blank";
        }
        setError(errors)
        if (Object.keys(errors).length) {
            return false;
        } else {
            return true;
        }
    }

    const handleChange = (e) => {
        const name = e.target.name;
        const value = e.target.value;
        if (name === 'userName') {
            setUserName(value);
        } else if (name === 'password') {
            setPassword(value);
        } else if (name === 'confirmPassword') {
            setConfirmPassword(value);
        } else if (name === 'verificationCode') {
            setVerificationCode(value);
        }

        let errorRef = error;
        errorRef[name] = null;
        setError(errorRef);
    }

    const handleReset = () => {
        const data = {
            userName
        }
        if (validateUseName(data)) {
            fetch('/resetPassword', {
                method: 'post',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({"username": userName}),
                credentials: "same-origin"
            })
            .then(function(response) {
                if (response.status!==200) {
                  response.json().then(function(err) {
                    console.error(err);
                    setError({resetError: "Failed to Reset"})
                  })
                } else {
                  response.json().then(function(object) {
                    setNextStep(true);
                  })
                }
            });
        }
        
    }

    const handleVerification = () => {
        const data = {
            userName,
            password,
            confirmPassword,
            verificationCode
        };
        if (validateData(data)) {
            fetch('/confirmPassword', {
                method: 'post',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(data),
                credentials: "same-origin"
            })
            .then(function(response) {
              if (response.status!==200) {
                response.json().then(function(err) {
                    console.error(err);
                    setError({verifyMsg : err.message});
                })
              } else {
                response.json().then(function(object) {
                    navigate('/sign_in');
                })
              }
            });
        }
    }

    return (
        <>
            <Header />
            <div className="container-fluid signin-container bg-dark">
                <div className="card card-login mx-auto text-center bg-dark">
                    <div className="card-header mx-auto bg-dark">
                        <span> <img src={logo} className="w-25" alt="Logo" /> </span><br />
                        <span className="logo_title mt-5"> Reset Password </span>
                    </div>
                    { !nextStep ? 
                        <div className="card-body">
                            {error.resetError && <span className='error-message'>{error.resetError}</span>}
                            <div>
                                <div className="input-group form-group">
                                    <div className="input-group-prepend">
                                        <span className="input-group-text"><i className="fa fa-user"></i></span>
                                    </div>
                                    <input type="text" className="form-control" name='userName' value={userName} onChange={(e) => handleChange(e)} placeholder="Email" />
                                </div>
                                {error.userName && <span className='error-message'>{error.userName}</span>}
                            </div>
                            <button className="btn float-right login_btn" onClick={() => handleReset()} >Reset</button>
                            <div className="redirect-text" onClick={() => navigate('/sign_up')}>Already have an account? Sign Up</div>
                        </div>
                        :
                        <div className="card-body">
                            {error.verifyMsg && <span className='error-message'>{error.verifyMsg}</span>}
                            <div>
                                <div className="input-group form-group">
                                    <div className="input-group-prepend">
                                        <span className="input-group-text"><i className="fa fa-key"></i></span>
                                    </div>
                                    <input type="password" className="form-control" placeholder="Password" name='password' value={password} onChange={(e) => handleChange(e)} />
                                </div>
                                {error.password && <span className='error-message'>{error.password}</span>}
                                <div className="input-group form-group">
                                    <div className="input-group-prepend">
                                        <span className="input-group-text"><i className="fa fa-key"></i></span>
                                    </div>
                                    <input type="password" className="form-control" placeholder="Confirm Password" name='confirmPassword' value={confirmPassword} onChange={(e) => handleChange(e)} />
                                </div>
                                {error.confirmPassword && <span className='error-message'>{error.confirmPassword}</span>}
                                <div className="input-group form-group">
                                    <div className="input-group-prepend">
                                        <span className="input-group-text"><i className="fa fa-key"></i></span>
                                    </div>
                                    <input type="password" className="form-control" placeholder="Email verification Code" name='verificationCode' value={verificationCode} onChange={(e) => handleChange(e)} />
                                </div>
                                {error.verificationCode && <span className='error-message'>{error.verificationCode}</span>}
                            </div>
                            <button className="btn float-right login_btn" onClick={() => handleVerification()} >Verify</button>
                        </div> 
                    }
                </div>
            </div>
        </>
    )
}

export default ResetPassword
