import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router'

import ConfirmationModal from './confirmationModal'
import ExpenseDetailsInput from './expenseDetailsInput'
import ExpenseTable from './expenseTable'
import './invoiceGenerator.scss'

const InvoiceGenerator = () => {

    const [pickupLocation, setPickupLocation] = useState('')
    const [dropLocation, setDropLocation] = useState('')
    const [kms, setKms] = useState('')
    const [hrs, setHrs] = useState('')
    const [address, setAddress] = useState('')
    const [name, setName] = useState('')
    const [email, setEmail] = useState('')
    const [contactNo, setContactNo] = useState('')
    const [carType, setCarType] = useState('Rental')
    const [startDate, setStartDate] = useState(new Date())
    const [endDate, setEndDate] = useState(new Date())
    const [expenses, setExpenses] = useState([])
    const [showExpenseDetailsInput, setShowExpenseDetailsInput] = useState(false)
    const [taxable, setTaxable] = useState('Yes')
    const [modal, setModal] = useState(false)
    const [btnFlag, setbtnFlag] = useState(false)
    const [msg, setMsg] = useState('')

    const handleGenerateInvoice = () => {
        setModal(true)
    }

    const initializeForm = () => {
        setPickupLocation('')
        setDropLocation('')
        setKms('')
        setHrs('')
        setAddress('')
        setName('')
        setEmail('')
        setContactNo('')
        setCarType('Rental')
        setStartDate(new Date())
        setEndDate(new Date())
        setExpenses([])
        setTaxable('Yes')
    }

    const handleModalConfirm = () => {

        setbtnFlag(true);
        setMsg('');
        const data = {
            pickupLocation,
            dropLocation,
            kms,
            hrs,
            address,
            name,
            email,
            contactNo,
            carType,
            startDate,
            endDate,
            taxable,
            expense: expenses
        }

        fetch('/createInvoice', {
            method: 'post',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(data),
            credentials: "same-origin"
        })
        .then(function (response) { return response.json(); })
        .then(res => {
            setMsg(res.invoiceName);
            setbtnFlag(false);
            setModal(false)
            initializeForm()

        })
        .catch((error) => {
            setMsg("err");
            setbtnFlag(false);
            console.log(error)
            setModal(false)
        });
    }

   
    // useEffect(() => {
    //     if (window.sessionStorage.getItem('userSession') !== 'active' || window.sessionStorage.getItem('userType') !== 'admin') {
    //         navigate('/')
    //     }
    // }, [])

    const addExpenses = (data) => {
        let expensesRef = expenses;
        expensesRef.push(data)
        setExpenses(expensesRef)
        setShowExpenseDetailsInput(false)
    }

    const handleDeleteExpense = (index) => {
        let expensesRef = expenses;
        setExpenses(expensesRef.filter((item, i) => i !== index))
    }


    return (
        <>
            <div className="invoice-title">
                Generate Invoice
            </div>

            <div className='invoive-details-container container'>
                <div className="row">
                    <div className="col-md">
                        Name
                        <input
                            className="form-control"
                            placeholder="Name"
                            value={name}
                            onChange={(e) => setName(e.target.value)}
                        />
                    </div>
                    <div className="col-md">
                        Contact Number
                        <input
                            className="form-control"
                            placeholder="Contact Number"
                            value={contactNo}
                            onChange={(e) => setContactNo(e.target.value)}
                        />
                    </div>
                </div>
                <div className="row">
                    <div className="col-md">
                        Email
                        <input
                            className="form-control"
                            placeholder="Email"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                        />
                    </div>
                    <div className="col-md">
                        Car Type
                        <select className="form-control" onChange={(e) => setCarType(e.target.value)}>
                            <option value="Rental"> Rentals</option>
                            <option value="Tour and Travel">Tour and Travels</option>
                        </select>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md">
                        Pickup Location
                        <input
                            className="form-control"
                            placeholder="Pickup location"
                            value={pickupLocation}
                            onChange={(e) => setPickupLocation(e.target.value)}
                        />
                    </div>
                    <div className="col-md">
                        Drop Location
                        <input
                            className="form-control"
                            placeholder="Drop location"
                            value={dropLocation}
                            onChange={(e) => setDropLocation(e.target.value)}
                        />
                    </div>
                </div>
                <div className="row">
                    <div className="col-md">
                        KMs
                        <input
                            type="text"
                            className="form-control"
                            value={kms}
                            placeholder='kms'
                            onChange={(e) => setKms(e.target.value)}
                        />
                    </div>
                    <div className="col-md">
                        Hrs
                        <input
                            type="text"
                            className="form-control"
                            value={hrs}
                            placeholder='hrs'
                            onChange={(e) => setHrs(e.target.value)}
                        />
                    </div>
                </div>

                <div className="row">
                    <div className="col-md">
                        Start Date
                        <input
                            type="date"
                            className="form-control"
                            value={startDate}
                            onChange={(e) => setStartDate(e.target.value)}
                        />
                    </div>
                    <div className="col-md">
                        End Date
                        <input
                            type="date"
                            className="form-control"
                            value={endDate}
                            onChange={(e) => setEndDate(e.target.value)}
                        />
                    </div>
                </div>
                <div className="row">
                    <div className="col-md">
                        Address
                        <input
                            type="text"
                            className="form-control"
                            value={address}
                            onChange={(e) => setAddress(e.target.value)}
                        />
                    </div>
                    <div className="col-md-2">
                        Taxable
                        <select className="form-control" onChange={(e) => setTaxable(e.target.value)}>
                            <option value="Yes">Yes</option>
                            <option value="No">No</option>
                        </select>
                    </div>
                </div>

                {expenses.length ? <ExpenseTable expenses={expenses} handleDeleteExpense={handleDeleteExpense} /> : null}

                <button className="find-button" onClick={() => setShowExpenseDetailsInput(true)}>
                    <i className="fa fa-plus" />&nbsp;
                    {expenses.length ? 'Add more expenses' : 'Add Expense'}
                </button>

                {showExpenseDetailsInput && <ExpenseDetailsInput addExpenses={addExpenses} />}

                {msg ? (msg != "err" ? <div className="success">
                    Invoice Created
                    <a className="download" href={"/downloadInvoice/" + msg} target="_blank">Download</a>
                </div>
                    : <div className="error">Failes to create Invoice</div>) : ''}

                <div className="row button-row">
                    <button className={btnFlag ? "find-button disaled" : "find-button"} onClick={() => handleGenerateInvoice()} disabled={btnFlag}>
                        Generate Invoice
                    </button>
                </div>
                <ConfirmationModal open={modal} confirm={() => handleModalConfirm()} cancel={() => setModal(false)} />
            </div>
        </>
    )
}

export default InvoiceGenerator
