import React from 'react'
import Header from '../header'
import './contact.scss'
import Footer from '../footer'

const Contact = () => {
    return (
        <>
            <Header />
            <div className='contact-container'>
                <div className="left-container">
                    <iframe
                        id="iframemap"
                        src="https://maps.google.com/maps?q=the+standard+journey+indore%2C+bhopal%2C+Madhya+Pradesh+452001&amp;ie=UTF8&amp;iwloc=&amp;output=embed" width="100%" height="500" frameborder="0" scrolling="no">
                    </iframe>
                </div>
                <div className="right-container">
                    <div className='contents'>
                        <div className='item'><i className="fa fa-map-marker" />
                            Indore Office:<br />
                            <p>Work (The Standard Journey (TSJ Cars Rental Services) Pvt Ltd)</p>
                            Address: Regency Arcade, 16 G, beside chartered bus, Indore, Madhya Pradesh 452001
                        </div>
                        <div className='item'><i className="fa fa-map-marker" />
                            Bhopal Office:<br/>
                            <p>Bhopal Office (The Standard Journey (TSJ Cars Rental Services Pvt Ltd))</p>
                            <p>Plot No 195 MP Nagar Zone 2 Sargam bus stop, near veer Street, Bhopal, Madhya Pradesh 462001</p>
                        </div>
                        <div className='item'>
                            <i className="fa fa-phone" />
                            <a href="tel:+0731-4035356" >07314035356</a>,&nbsp;
                            <a href="tel:91091-23404" >9109123404</a>
                        </div>
                        <div className='item'>
                            <i className="fa fa-envelope-o" />
                            <a href="mailto:info@thestandardjourney.com">
                                info@thestandardjourney.com
                            </a>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </>
    )
}

export default Contact
