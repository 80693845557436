import React, { useState, useEffect } from "react";
import "./adminDashboard.scss";
import Header from "../header";
import InvoiceGenerator from "../invoiceGenerator";
// import { carsData } from '../../utilsData/carsData'
import CapsuleButtonGroup from "../miscellaneous/capsuleButtonGroup";
import CarComponent from "../chooseCar/carComponent";
import CarForm from "./carForm";
import Footer from "../footer";
import OccupancyForm from "./occupancyForm";
import DiscountForm from "./discountForm";
import Bookings from "./bookings";
import PackageCards from "./packageCards";
import { packageCardsData } from "../../utilsData/packageCardsData";
import TourCardForm from "./tourCardForm";
import axios from "axios";
import ViewInvoices from "../viewInvoices";
import { Enquirytable } from "../enquiry/Enquirytable";
import { Userdetail } from "../user/Userdetail.jsx";
import { Quotationgenerator } from "../quotationgenerator/Index";

const AdminDashBoard = () => {
  const [occupancyData, setOccupancyData] = useState({});
  const [selectedOption, setSelectedOption] = useState(0);
  const [carToEdit, setCarToEdit] = useState(-1);
  const [openForm, setOpenForm] = useState(false);
  const [openDiscountForm, setOpenDiscountForm] = useState(false);
  const [openOccupancyForm, setOpenOccupancyForm] = useState(false);
  const [openCardForm, setOpenCardForm] = useState(false);
  const [tourCardData, setTourCardData] = useState([]);
  const [taxiCardData, setTaxiCardData] = useState([]);
  const [tourCardToEdit, setTourCardToEdit] = useState(-1);
  const [carsData, setCarsData] = useState([]);
  const [updateIfChange, setUpdateIfChange] = useState({});

  useEffect(() => {
    //fetch occupancy data from api
    let dummyOccupancyData = {
      total: 10,
      available: 9,
    };
    setOccupancyData(dummyOccupancyData);

    //fetch tourCardData from api
    axios.get("taxis").then((res) => {
      setTaxiCardData(res.data);
    });

    axios.get("tours").then((res) => {
      setTourCardData(res.data);
    });
  }, []);

  const getCarsData = () => {
    fetch("/cars", {
      method: "get",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      credentials: "same-origin",
    }).then(function (response) {
      if (response.status !== 200) {
        response.json().then(function (err) {
          console.log(err.message);
        });
      } else {
        response.json().then(function (data) {
          setCarsData(data);
        });
      }
    });
  };

  useEffect(() => {
    getCarsData();
  }, [updateIfChange]);

  const handleSelection = (index) => {
    setSelectedOption(index);
  };

  const options = [
    {
      name: "Cars",
      value: "cars",
    },
    {
      name: "Generate Invoice",
      value: "generateInvoice",
    },
    {
      name: "View Bookings",
      value: "viewBookings",
    },
    {
      name: "Tour Cards",
      value: "tourCards",
    },
    {
      name: "Taxi Cards",
      value: "taxiCards",
    },
    {
      name: "View Invoices",
      value: "viewInvoices",
    },

    {
      name: "View Enquiries",
      value: "view Enquiries",
    },
    
    {
      name: "User Details",
      value: "user details",
    },

    {
      name: "Generate Quotation",
      value: "generate quotation",
    },

  ];

  const handleEditClick = (index) => {
    setOpenForm(true);
    setCarToEdit(index);
  };

  const handleCardEditClick = (index) => {
    console.log("index", index);
    console.log("ttt");
    setTourCardToEdit(index);
    setOpenCardForm(true);
  };

  const handleAddNewClick = () => {
    setOpenForm(true);
    setCarToEdit(-1);
  };

  const handleAddNewCard = () => {
    setOpenCardForm(true);
    setTourCardToEdit(-1);
  };

  const handleSubmitModal = (data) => {
    console.log("data", data);
    let httpMethod = carToEdit > -1 ? "put" : "post";
    fetch("/car", {
      method: httpMethod,
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
      credentials: "same-origin",
    }).then(function (response) {
      if (response.status !== 200) {
        response.json().then(function (err) {
          console.error(err.message);
        });
      } else {
        response.json().then(function (object) {
          setUpdateIfChange(object);
        });
      }
    });
    setOpenForm(false);
    setCarToEdit(-1);
  };

  const newCarObject = {
    name: "",
    fuelType: "",
    seater: "",
    price: "",
    image: "",
    offers: [],
    id: "",
  };

  const newTourCardData = {
    title: "",
    image: "",
    description: "",
    price: "",
    available: 0.0,
  };

  const submitTourCardForm = (data) => {
    let requestType = "post";
    if (tourCardToEdit !== -1) {
      requestType = "put";
    }
    let url = "taxi";
    if (selectedOption === 3) {
      url = "tour";
    }
    axios({
      method: requestType,
      url: url,
      data: data,
    }).then((res) => {
      console.log(res);
      setOpenCardForm(false);
      getCarsData();
    });
  };

  return (
    <div className="adminDashboard">
      <Header />
      <div className="optionsBar">
        <CapsuleButtonGroup
          data={options}
          selected={selectedOption}
          handleButtonClick={handleSelection}
        />
      </div>
      {selectedOption === 1 ? (
        <InvoiceGenerator />
      ) : selectedOption === 5 ? (
        <ViewInvoices />
      ) : selectedOption === 0 ? (
        <div className="carsContainer">
          <div className="button-row">
            <div className="add-new-car" onClick={() => handleAddNewClick()}>
              <i className="fa fa-plus" />
              &nbsp; Add New Car
            </div>
            <button
              className="button"
              onClick={() => setOpenDiscountForm(true)}
            >
              Add Global Discount
            </button>
            <button
              className="button"
              onClick={() => setOpenOccupancyForm(true)}
            >
              Update Occupancy
            </button>
          </div>
          {carsData.map((item, index) => (
            <CarComponent
              key={index}
              data={item}
              index={index}
              forAdmin={true}
              handleButtonClick={handleEditClick}
            />
          ))}
        </div>
      ) : selectedOption === 2 ? (
        <Bookings />
      ) : selectedOption === 6 ? (
        <Enquirytable />
      ) : selectedOption === 7 ? (
        <Userdetail />
      ) :  selectedOption === 8 ? (
        <Quotationgenerator />
      )  :(
        <div className="carsContainer">
          <div className="button-row">
            <div className="add-new-car" onClick={() => handleAddNewCard()}>
              <i className="fa fa-plus" />
              &nbsp; Add New Card
            </div>
          </div>
          <PackageCards
            data={selectedOption === 3 ? tourCardData : taxiCardData}
            onButtonClick={handleCardEditClick}
          />
        </div>
      )}
      <OccupancyForm
        open={openOccupancyForm}
        data={occupancyData}
        confirm={() => setOpenOccupancyForm(false)}
        cancel={() => setOpenOccupancyForm(false)}
      />
      <DiscountForm
        open={openDiscountForm}
        confirm={() => setOpenDiscountForm(false)}
        cancel={() => setOpenDiscountForm(false)}
      />
      <TourCardForm
        open={openCardForm}
        data={
          tourCardToEdit > -1
            ? selectedOption === 3
              ? tourCardData[tourCardToEdit]
              : taxiCardData[tourCardToEdit]
            : newTourCardData
        }
        confirm={(e) => submitTourCardForm(e)}
        cancel={() => setOpenCardForm(false)}
        isEdit={tourCardToEdit !== -1}
      />
      <CarForm
        open={openForm}
        data={carToEdit > -1 ? carsData[carToEdit] : newCarObject}
        confirm={(e) => handleSubmitModal(e)}
        cancel={() => setOpenForm(!openForm)}
        isNew={carToEdit === -1}
      />
      <Footer />
    </div>
  );
};

export default AdminDashBoard;