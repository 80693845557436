import React from 'react'
import { Link } from 'react-router-dom'
import companyLogo from '../../assets/company_logo.jpeg'
import './footer.scss'

const Footer = () => {
	return (
		<div className='container-fluid footer'>
			<div className='row justify-content-around'>
				<div className='col-sm-4 left-container'>
					<div className='row'>
						<img className='company-icon' src={companyLogo} />
					</div>
					<div className='row'>
						<ul>
							<li><i className="fa fa-map-marker"></i>
								Indore Office:<br />
								<p>Work (The Standard Journey (TSJ Cars Rental Services) Pvt Ltd)</p>
								Address: Regency Arcade, 16 G, beside chartered bus, Indore, Madhya Pradesh 452001
							</li>

							<li><i className="fa fa-map-marker"></i>
								Bhopal Office:<br />
								<p>Bhopal Office (The Standard Journey (TSJ Cars Rental Services Pvt Ltd))</p>
								<p>Plot No 195 MP Nagar Zone 2 Sargam bus stop, near veer Street, Bhopal, Madhya Pradesh 462001</p>
							</li>
							<li>
								<i className="fa fa-phone" />
								<a href="tel:+0731-4035356" >07314035356</a>,&nbsp;
								<a href="tel:91091-23404" >9109123404</a>
							</li>
							<li>
								<a href="mailto:info@thestandardjourney.com">
									<i className="fa fa-envelope-o" />
									info@thestandardjourney.com
								</a>
							</li>
						</ul>
					</div>
				</div>
				<div className='col-sm-4'>

					<h3>Featured links</h3>
					<div className='row mt-3'>
						<div className='col'>
							<ul>
								{/* <li><Link to='/'><i class="fa fa-caret-right"></i>Home</Link></li> */}
								{/* <li><Link to='/about_us'><i className="fa fa-caret-right"></i>About Us</Link></li> */}
								<li><Link to='/car_rental'><i className="fa fa-caret-right"></i>Services</Link></li>
								<li><Link to='/choose_car'><i className="fa fa-caret-right"></i>Our Packages</Link></li>
								<li><Link to='/contact'><i className="fa fa-caret-right"></i>Contact</Link></li>
							</ul>
						</div>
						{/* <div className='col'>
							<ul>
								 <li><Link to='/'><i class="fa fa-caret-right"></i>Image Gallery</Link></li>
								<li><Link to='/'><i class="fa fa-caret-right"></i>Video Gallery</Link></li> 
								<li><Link to='/our_packages'><i class="fa fa-caret-right"></i>Our Packages</Link></li>
								<li><Link to='/contact'><i class="fa fa-caret-right"></i>Contact</Link></li>
							</ul>

						</div> */}

					</div>
				</div>
			</div>
		</div>
	)
}

export default Footer
