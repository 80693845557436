import axios from "axios";
import React, { useEffect, useState } from "react";

export const Enquirytable  = () => {

    const [enquiries, setEnquiries] = useState([])

    useEffect(() => {
        axios.get('/get-enquiry')
            .then(res => {
                setEnquiries(res.data.data)
            })
            .catch(err => {
                console.log(err);
                alert('failed to load queries')
            })
    }, [])

    return(

            // <div>
            //     Shiv
            // </div>

            <div>
            <table className="table table-bordered my-5">
                <thead>
                    <tr>
                        <th scope="col">S No</th>
                        <th scope="col">Name</th>
                        <th scope="col">Mobile</th>
                        <th scope="col">Email</th>
                        <th scope="col">Requirements</th>
                    </tr>
                </thead>
                <tbody>
                    {/* {expenses.map(() => {
                        return ( */}
                            {/* <tr>
                                <th scope="row">0</th>
                                <td>Demo name</td>
                                <td>0000000</td>
                                <td>demo@gmail.com</td>
                                <td>demo</td>
                                <td> */}
                                    {/* <i
                                        style={{ cursor: "pointer" }}
                                        className="fa fa-trash"
                                        onClick={() => handleDeleteExpense(index)}
                                    /> */}
                                {/* </td>
                            </tr> */}
                        {/* ) */}
                    {/* })} */}

                    {enquiries.length ? enquiries.map((enq, index) =>
                        <tr key={enq.id}>
                            <td>{index + 1}</td>
                            <td>{enq.name}</td>
                            <td>{enq.mobile}</td>
                            <td>{enq.email}</td>
                            <td>{enq.requirements}</td>
                        </tr>
                    ) : null}
                </tbody>
            </table>
        </div>

    );
}