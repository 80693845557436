import React, { useState, useEffect } from 'react'
import Header from '../header'
import profilePic from '../../assets/sample_profile.png'
import './dashboard.scss'
import CapsuleButtonGroup from '../miscellaneous/capsuleButtonGroup'
import EditModal from './editMoal'
import { tncData } from '../../utilsData/tncData'
import { useCookies } from "react-cookie";
import axios from 'axios'

const DashBoard = () => {
    const [selectedView, setSelectedView] = useState('profile')
    const [openEditModal, setOpenEditModal] = useState(false)
    const [profileData, setProfileData] = useState({})
    const [selectedBookingCategory, setSelectedBookingCategory] = useState(0)
    const [bookings, setBookings] = useState([])

    const [cookies, setCookie] = useCookies()

    useEffect(() => {
        if (cookies && cookies.userData) {
            setProfileData(cookies.userData);
            console.log(cookies.userData ,"Checkkk")
            axios.get('currentUserBookings', { params: { username: cookies.userData.username } })
                .then(res => {
                    let bookingsData = []
                    bookingsData[0] = res.data
                    bookingsData[1] = res.data.filter(item => new Date(item.startDate) >= new Date())
                    bookingsData[2] = res.data.filter(item => new Date(item.startDate) < new Date())
                    setBookings(bookingsData)
                })
        }
    }, []);

    const bookingsCategory = [
        {
            name: "All",
            value: 'all'
        },
        {
            name: "Upcoming Bookings",
            value: 'upcoming'
        },
        {
            name: "Previous Bookings",
            value: 'previous'
        },
    ]

    const handleBookingCategory = (index) => {
        setSelectedBookingCategory(index)
    }

    const profileContent = <div className="profileContent">
        <i className="fa fa-edit right-end" onClick={() => setOpenEditModal(true)}></i>
        <div className="row">
            <div className="col label">
                Name :
            </div>
            <div className="col">{profileData.name}</div>
        </div>
        <div className="row">
            <div className="col label">
                Mobile :
            </div>
            <div className="col">{profileData.phone}</div>
        </div>
        <div className="row">
            <div className="col label">
                Email :
            </div>
            <div className="col">{profileData.email}</div>
        </div>
        <div className="row">
            <div className="col label">
                Aadhar No. :
            </div>
            <div className="col">{profileData.aadhar}</div>
        </div>
        <div className="row">
            <div className="col label">
                Driving License :
            </div>
            <div className="col">{profileData.license}</div>
        </div>
    </div>

    const bookingContent = <div>
        <CapsuleButtonGroup data={bookingsCategory} selected={selectedBookingCategory} handleButtonClick={handleBookingCategory} />
        {
            bookings && bookings[selectedBookingCategory]?.length ?

                <div className="table-responsive-sm">
                    <table class="table ">
                        <thead>
                            <tr>
                                <th >#</th>
                                <th >Name</th>
                                <th >Start Date</th>
                                <th >End Date</th>
                                <th >Amount</th>
                            </tr>
                        </thead>
                        <tbody>
                            {bookings[selectedBookingCategory].map((item, index) =>
                                <tr key={index}>
                                    <th scope="row">{index + 1}</th>
                                    <td>{item.name}</td>
                                    <td>{item.startDate?.substring(0, 10)}</td>
                                    <td>{item.endDate?.substring(0, 10)}</td>
                                    <td>₹{item.amount}</td>
                                </tr>
                            )}
                        </tbody>
                    </table>
                </div>
                : 'No Bookings Available'
        }
    </div>


    return (
        <div className="">
            <Header />
            <div className='container-fluid dashboard'>
                <div className='row'>
                    <div className="col-md-3 p0">
                        <div className="leftContainer">
                            {/* <img className="image" src={profilePic} /> */}
                            <div className="contentList">
                                <div className={`leftContent ${selectedView === 'profile' ? 'active' : null}`} onClick={() => setSelectedView('profile')}>
                                    <span>Profile</span>
                                </div>
                                <div className={`leftContent ${selectedView === 'myBookings' ? 'active' : null}`} onClick={() => setSelectedView('myBookings')}>
                                    <span>My Bookings</span>
                                </div>
                                <div className={`leftContent ${selectedView === 'tnc' ? 'active' : null}`} onClick={() => setSelectedView('tnc')}>
                                    <span>Term & Conditions</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col rightContainer">
                        {selectedView === 'profile' && profileContent}
                        {selectedView === 'myBookings' && bookingContent}
                        {selectedView === 'tnc' && tncData}
                    </div>
                </div>
            </div>
            <EditModal profileData={profileData} open={openEditModal} data={profileData} confirm={() => setOpenEditModal(!openEditModal)} cancel={() => setOpenEditModal(!openEditModal)} />
        </div>
    )
}

export default DashBoard
