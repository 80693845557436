import React, { useState } from "react";
import { Quotationinput } from "./Quotationinput";
import { Quotationtable } from "./Quotationtable";
import './quotationgenerator.scss'
import { Confirmmodal } from "./Confirmmodal";
import { useNavigate } from "react-router";

export const Quotationgenerator = () => {

    const [pickupLocation, setPickupLocation] = useState('')
    const [dropLocation, setDropLocation] = useState('')
    const [kms, setKms] = useState('')
    const [hrs, setHrs] = useState('')
    const [address, setAddress] = useState('')
    const [name, setName] = useState('')
    const [email, setEmail] = useState('')
    const [contactNo, setContactNo] = useState('')
    const [carType, setCarType] = useState('Rental')
    const [startDate, setStartDate] = useState(new Date())
    const [endDate, setEndDate] = useState(new Date())
    const [quotations, setQuotations] = useState([])
    const [showQuotationInput, setShowQuotationInput] = useState(false)
    const [taxable, setTaxable] = useState('Yes')
    const [modal, setModal] = useState(false)
    const [btnFlag, setbtnFlag] = useState(false)
    const [msg, setMsg] = useState('');

    const handleGenerateQuotation = () => {
        setModal(true)
    }

    const initializeForm = () => {
        setPickupLocation('')
        setDropLocation('')
        setKms('')
        setHrs('')
        setAddress('')
        setName('')
        setEmail('')
        setContactNo('')
        setCarType('Rental')
        setStartDate(new Date())
        setEndDate(new Date())
        setQuotations([])
        setTaxable('Yes')
    }

    const handleModalConfirm = () => {

        setbtnFlag(true);
        setMsg('');
        const data = {
            pickupLocation,
            dropLocation,
            kms,
            hrs,
            address,
            name,
            email,
            contactNo,
            carType,
            startDate,
            endDate,
            taxable,
            quotation: quotations
        }

        fetch('/createQuotation', {
            method: 'post',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(data),
            credentials: "same-origin"
        })
        .then(function (response) { return response.json(); })
        .then(res => {
            setMsg(res.invoiceName);
            setbtnFlag(false);
            setModal(false)
            initializeForm()

        })
        .catch((error) => {
            setMsg("err");
            setbtnFlag(false);
            console.log(error)
            setModal(false)
        });
    }

    const navigate = useNavigate()


    const addQuotations = (data) => {
        let quotationRef = quotations;
        quotationRef.push(data)
        setQuotations(quotationRef)
        setShowQuotationInput(false)
    }

    const handleDeleteQuotation = (index) => {
        let quotationRef = quotations;
        setQuotations(quotationRef.filter((item, i) => i !== index))
    }

    return(

        // <div>
        //     Shiv
        // </div>

        <>
        <div className="invoice-title">
            Generate Quotation
        </div>

        <div className='invoive-details-container container'>
            <div className="row">
                <div className="col-md">
                    Name
                    <input
                        className="form-control"
                        placeholder="Name"
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                    />
                </div>
                <div className="col-md">
                    Contact Number
                    <input
                        className="form-control"
                        placeholder="Contact Number"
                        value={contactNo}
                        onChange={(e) => setContactNo(e.target.value)}
                    />
                </div>
            </div>
            <div className="row">
                <div className="col-md">
                    Email
                    <input
                        className="form-control"
                        placeholder="Email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                    />
                </div>
                <div className="col-md">
                    Car Type
                    <select className="form-control" 
                    onChange={(e) => setCarType(e.target.value)}
                    >
                        <option value="Rental"> Rentals</option>
                        <option value="Tour and Travel">Tour and Travels</option>
                    </select>
                </div>
            </div>
            <div className="row">
                <div className="col-md">
                    Pickup Location
                    <input
                        className="form-control"
                        placeholder="Pickup location"
                        value={pickupLocation}
                        onChange={(e) => setPickupLocation(e.target.value)}
                    />
                </div>
                <div className="col-md">
                    Drop Location
                    <input
                        className="form-control"
                        placeholder="Drop location"
                        value={dropLocation}
                        onChange={(e) => setDropLocation(e.target.value)}
                    />
                </div>
            </div>
            <div className="row">
                <div className="col-md">
                    KMs
                    <input
                        type="text"
                        className="form-control"
                        value={kms}
                        placeholder='kms'
                        onChange={(e) => setKms(e.target.value)}
                    />
                </div>
                <div className="col-md">
                    Hrs
                    <input
                        type="text"
                        className="form-control"
                        value={hrs}
                        placeholder='hrs'
                        onChange={(e) => setHrs(e.target.value)}
                    />
                </div>
            </div>

            <div className="row">
                <div className="col-md">
                    Start Date
                    <input
                        type="date"
                        className="form-control"
                        value={startDate}
                        onChange={(e) => setStartDate(e.target.value)}
                    />
                </div>
                <div className="col-md">
                    End Date
                    <input
                        type="date"
                        className="form-control"
                        value={endDate}
                        onChange={(e) => setEndDate(e.target.value)}
                    />
                </div>
            </div>
            <div className="row">
                <div className="col-md">
                    Address
                    <input
                        type="text"
                        className="form-control"
                        value={address}
                        onChange={(e) => setAddress(e.target.value)}
                    />
                </div>
                <div className="col-md-2">
                    Taxable
                    <select className="form-control" 
                    onChange={(e) => setTaxable(e.target.value)}
                    >
                        <option value="Yes">Yes</option>
                        <option value="No">No</option>
                    </select>
                </div>
            </div>

            {quotations.length ? <Quotationtable quotations={quotations} handleDeleteQuotation={handleDeleteQuotation} /> : null}

            <button className="find-button"
             onClick={() => setShowQuotationInput(true)}
             >
                <i className="fa fa-plus" />&nbsp;
                {quotations.length ? 'Add more quotationss' : 'Add Quotation'}
            </button>

            {showQuotationInput && <Quotationinput addQuotations={addQuotations} />}

            {msg ? (msg !== "err" ? <div className="success">
                Quotation Created
                <a type="button" className="download" href={"/downloadQuotation/" + msg} target="_blank" >Download</a>
            </div>
                : <div className="error">Failes to create Quotation</div>) : ''}

            <div className="row button-row">
                <button className={btnFlag ? "find-button disaled" : "find-button"} onClick={() => handleGenerateQuotation()} disabled={btnFlag}>
                    Generate Quotation
                </button>
            </div>
            <Confirmmodal open={modal} confirm={() => handleModalConfirm()} cancel={() => setModal(false)} />
        </div>
    </>

    );
}