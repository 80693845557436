import React, { useState } from 'react'
import './periodFilter.scss'

const PeriodFilter = ({
    selectedFilter,
    startDate,
    endDate,
    setSelectedFilter,
    setStartDate,
    setEndDate,
    handleApplyFilter
}) => {
    // const [selectedFilter, setSelectedFilter] = useState(0)
    // const [startDate, setStartDate] = useState(0)
    // const [endDate, setEndDate] = useState(0)
    console.log('selectedFilter', selectedFilter);
    const fiterCriteria = [
        {
            name: 'All',
            value: 'all'
        },
        {
            name: 'This Month',
            value: 'this_month'
        },
        {
            name: 'Last Month',
            value: 'last_month'
        },
        {
            name: 'Custom Dates',
            value: 'custom'
        },
    ]
    return (
        <div className="">
            <div className='filterContainer'>
                <label>Select Filter Type</label>
                <select onChange={(e) => setSelectedFilter(e.target.value)}>
                    {
                        fiterCriteria.map(item =>
                            <option value={item.value}>{item.name}</option>
                        )
                    }
                </select>
                <div className='button' onClick={() => handleApplyFilter()}>Apply</div>
            </div>
            {selectedFilter === 'custom' &&
                <div className="dateContainer">
                    <input
                        type="date"
                        value={startDate}
                        onChange={(e) => setStartDate(e.target.value)}
                    />
                    <span className='to'>
                        to
                    </span>
                    <input
                        type="date"
                        value={endDate}
                        onChange={(e) => setEndDate(e.target.value)}
                    />
                </div>
            }

        </div>
    )
}

export default PeriodFilter
