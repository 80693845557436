import React from 'react'
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { useCookies } from "react-cookie";
import axios from 'axios';

const PackageDetailsContainer = ({ open, selectedPackageData, packageDetails, handleCancel, packageType }) => {

    const [cookies, setCookie] = useCookies()

    const handlePayNow = () => {
        setCookie('packageDetails', getBokingObj())

        const data = {
            "order_id": Date.now().toString(),
            "order_amount": parseFloat(selectedPackageData.price),
            "order_currency": "INR",
            "customer_details": {
                "customer_id": packageDetails.name.split(' ').join(''),
                "customer_email": packageDetails.mail,
                "customer_phone": packageDetails.mobileNo,
                "customer_name": packageDetails.name,
            },
            "order_meta": {
                "return_url": `https://thestandardjourney.com/payment_status?order_id={order_id}&order_token={order_token}`
            }
        }

        const orderUrl = '/orders';

        const options = {
            method: 'POST',
            url: orderUrl,
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json'
            },
            data: data
        };

        axios.request(options).then(function (response) {
            console.log(response.data);
            window.location.href = response.data.payment_link;
        }).catch(function (error) {
            console.error(error?.response);
            const message = error?.response?.data?.message?.split(':')[1]
            alert(message)
        });

    }

    const handleBooking = () => {
        const bookingData = getBokingObj()
        axios({
            method: 'post',
            url: 'booking',
            data: bookingData
        })
            .then(res => {
                alert('booking confirmed')
                handleCancel()
            })
    }

    const getBokingObj = () => {
        const bookingData = {
            name: packageDetails.name,
            mobile: packageDetails.mobileNo,
            email: packageDetails.mail,
            pickup: packageDetails.pickupLocation,
            drop: packageDetails.dropLocation,
            hrs: packageDetails.hrs,
            kms: packageDetails.kms,
            startDate: packageDetails.startDate,
            endDate: packageDetails.endDate,
            packageType: packageType,
            packageTitle: '',
            packageId:packageDetails.packageId,
            username: packageDetails.name,
            amount: packageDetails.amount
        }
        return bookingData
    }

    const handlePayLater = () => {
        handleBooking()
    }


    if (!selectedPackageData) return null;
    return (
        <div>
            <Modal isOpen={open} toggle={() => handleCancel()} >
                <ModalHeader toggle={() => handleCancel()}>Package Details</ModalHeader>
                <ModalBody>
                    <div className="row">
                        <div className="col-4">Name :</div>
                        <div className="col-auto">{packageDetails.name}</div>
                    </div>
                    <div className="row">
                        <div className="col-4">Pickup Location :</div>
                        <div className="col-auto">{packageDetails.pickupLocation}</div>
                    </div>
                    <div className="row">
                        <div className="col-4">Drop Location :</div>
                        <div className="col-auto">{packageDetails.dropLocation}</div>
                    </div>
                    <div className="row">
                        <div className="col-4">Contact No. :</div>
                        <div className="col-auto">{packageDetails.mobileNo}</div>
                    </div>
                    <div className="row">
                        <div className="col-4">Email :</div>
                        <div className="col-auto">{packageDetails.mail}</div>
                    </div>
                    <div className="row">
                        <div className="col-4">Start Date :</div>
                        <div className="col-auto">{packageDetails.startDate}</div>
                    </div>
                    <div className="row">
                        <div className="col-4">End Date :</div>
                        <div className="col-auto">{packageDetails.endDate}</div>
                    </div>
                    <div className="row">
                        <div className="col-4">Kilometers :</div>
                        <div className="col-auto">{packageDetails.kms}</div>
                    </div>
                    <div className="row">
                        <div className="col-4">Hours :</div>
                        <div className="col-auto">{packageDetails.hrs}</div>
                    </div>
                    <div className="row">
                        <div className="col-4">Total Amount :</div>
                        <div className="col-auto">{selectedPackageData.price}</div>
                    </div>
                    <br />

                </ModalBody>
                <ModalFooter>
                    <Button color="primary" onClick={() => handlePayNow()}>{'Pay Now'}</Button>{' '}
                    <Button color="primary" onClick={() => handlePayLater()}>{'Pay Later'}</Button>{' '}
                    <Button color="secondary" onClick={() => handleCancel()}>Cancel</Button>
                </ModalFooter>
            </Modal>
        </div>
    )
}

export default PackageDetailsContainer