import React from "react";
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";

export const Confirmmodal = ({ open, confirm, cancel }) => {
    return(

        // <div>
        //     Shiv
        // </div>

        <div>
        <Modal isOpen={open} toggle={() => cancel()} >
            <ModalHeader toggle={() => cancel()}>Are you sure!</ModalHeader>
            <ModalBody>
                Are you sure you  want to generate quotation with the given details?
            </ModalBody>
            <ModalFooter>
                <Button color="primary" onClick={() => confirm()}>Confirm</Button>{' '}
                <Button color="secondary" onClick={() => cancel()}>Cancel</Button>
            </ModalFooter>
        </Modal>
    </div>

    );
}