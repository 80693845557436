import React, { useEffect } from 'react'
import PackageCard from '../packageCard'
import './packageCards.scss'

const PackageCards = ({ data, onButtonClick }) => {

    return (
        <div className="packageCards">
            {data.map((item, index) => <PackageCard key={index} index={index} data={item} buttonText='Edit' onButtonClick={() => onButtonClick(index)} />)}
        </div>
    )
}

export default PackageCards
