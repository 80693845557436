import React, { useState } from 'react'
import { useNavigate } from 'react-router'

import whiteHamburger from '../../../assets/whiteHamburger.svg';
import HamburgerMenu from '../../header/hamburgerMenu';
import './servicePageBanner.scss'
import companyLogo from '../../../assets/company_logo.jpeg'
import { Link } from 'react-router-dom';

const ServicePageBanner = ({ banner, bannerButtonsContent }) => {

    const [openMenu, setOpenMenu] = useState(false)

    const history = useNavigate()

    return (
        <div
            className='container-fluid banner'
            style={{
                backgroundImage: `url(${banner})`
            }}>
            <div className='header-on-banner'>
                <div className='logo'>
                    <img className='image' src={companyLogo} onClick={() => history('/')} />
                </div>
                {openMenu ?
                    <HamburgerMenu closeHamburger={() => setOpenMenu(false)} />
                    :
                    <div className='menu-icon' onClick={() => setOpenMenu(true)} >
                        <img src={whiteHamburger} className='items image' />
                    </div>
                }
            </div>

            {
                bannerButtonsContent &&
                <div className='row banner-button-row'>
                    {bannerButtonsContent.map(item => {
                        return (
                            <div className="col banner-button-wrapper">
                                <Link to={`${item.route}`}
                                    state={{ tab: `${item.tab}` }} >
                                    <button>
                                        {item.text}
                                    </button>
                                </Link>
                            </div>
                        )
                    })}
                </div>
            }

        </div>
    )
}

export default ServicePageBanner
