export const kmsData = [
    {
        name: '120 Kms',
        value: 120
    },
    {
        name: '250 Kms',
        value: 250
    },
]

export const serviceTypeData = [
    {
        name: 'Rental Services',
        value: 'rental_services'
    },
    {
        name: 'Tour & Travel Services',
        value: 'tour_and_travel_services'
    },
    {
        name: 'Taxi Services',
        value: 'taxi_services'
    },
]