import React from 'react'
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';

const ConfirmationModal = ({ open, confirm, cancel }) => {

    return (
        <div>
            <Modal isOpen={open} toggle={() => cancel()} >
                <ModalHeader toggle={() => cancel()}>Are you sure!</ModalHeader>
                <ModalBody>
                    Are you sure you  want to generate invoice with the given details?
                </ModalBody>
                <ModalFooter>
                    <Button color="primary" onClick={() => confirm()}>Confirm</Button>{' '}
                    <Button color="secondary" onClick={() => cancel()}>Cancel</Button>
                </ModalFooter>
            </Modal>
        </div>
    );
}

export default ConfirmationModal;