import axios from 'axios'
import React, { useState, useEffect } from 'react'
import PeriodFilter from '../miscellaneous/periodFilter'
import './bookings.scss'
import DisplayBookingDetailsModal from './displayBookingDetailsModal'

const Bookings = () => {

    const [selectedFilter, setSelectedFilter] = useState('all')
    const [startDate, setStartDate] = useState('')
    const [endDate, setEndDate] = useState('')
    const [bookingData, setBookingData] = useState([])
    const [bookingDetails, setBookingDetails] = useState({})
    const [open, setOpen] = useState(false)

    useEffect(() => {
        axios.get('bookings')
        .then(res => {
            setBookingData(res.data)
        })
    }, []) 

    const getBookingDetails = (id) => {
        setOpen(true)
        axios.get('/booking', { params: { id } })
            .then(res => {
                const details = {
                    userBookingData: bookingData.find(booking => booking.id === id),
                    carData: res.data.dataValues
                }
                setBookingDetails(details)
            })
    }

    const handleApplyFilter = () => {
        console.log('ses', selectedFilter);
        console.log('ses1', startDate);
        console.log('ses2', endDate);
        //api call to fetch bookings data with above values and set response to bookingData
    }
    return (
        <div className='bookingsContainer'>
            <PeriodFilter
                selectedFilter={selectedFilter}
                startDate={startDate}
                endDate={endDate}
                setSelectedFilter={setSelectedFilter}
                setStartDate={setStartDate}
                setEndDate={setEndDate}
                handleApplyFilter={handleApplyFilter}
            />
            {
                bookingData?.length ?
                <div className="table-responsive-sm">
                    <table class="table ">
                        <thead>
                            <tr>
                                <th >#</th>
                                <th >Name</th>
                                <th >Start Date</th>
                                <th >End Date</th>
                                <th >Amount</th>
                            </tr>
                        </thead>
                        <tbody>
                            {bookingData.map((item, index) =>
                                <tr key={index} onClick={() => getBookingDetails(item.id)}>
                                    <th scope="row">{index + 1}</th>
                                    <td>{item.name}</td>
                                    <td>{item.startDate?.substring(0, 10)}</td>
                                    <td>{item.endDate?.substring(0, 10)}</td>
                                    <td>₹{item.amount}</td>
                                </tr>
                            )}
                        </tbody>
                    </table>
                    </div>
                    :
                    <div className="noBookings">No Bookings Found</div>
            }
            {bookingDetails?.userBookingData && <DisplayBookingDetailsModal open={open} handleCancel={() => setOpen(false)} data={bookingDetails} />}
        </div>
    )
}
export default Bookings
