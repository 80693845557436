import React from 'react'
import { connect } from 'react-redux'
import './leftContainer.scss'

const LeftContainer = ({
    seaterFilter,
    fuelTypeFilter,
    handleSeaterFilter,
    handleFuelTypeFilter,
    distance,
    setDistance,
    time,
    setTime
}) => {


    const seatFilter = ['4', '5', '6', '6+']
    const fuelFilter = ['diesel', 'petrol']

    return (
        <div className='filter-container'>
            <div className="occupancy-container">
                <div className="occupancy-header">
                    Car Occupancy
                </div>
                <div className="occupancy-content">
                    <div className="occupancy-content-element">
                        Total
                        <span>10</span>
                    </div>
                    <div className="occupancy-content-element">
                        Available
                        <span>9</span>
                    </div>
                </div>
            </div>
            <div className="filter-heading">Search by Filter</div>
            <div className="filter-card">
                <div className="filter-card-type">
                    Distance(in kms)
                </div>
                <div className="filter-card-content">
                    <input className='filter-card-content-element' type='number' onChange={(e) => setDistance(e.target.value)} value={distance} />
                </div>
            </div>
            <div className="filter-card">
                <div className="filter-card-type">
                    Time(in Hrs)
                </div>
                <div className="filter-card-content">
                    <input className='filter-card-content-element' onChange={(e) => setTime(e.target.value)} value={time} />
                </div>
            </div>
            <div className="filter-card">
                <div className="filter-card-type">
                    Car seats
                </div>
                <div className="filter-card-content">
                    {seatFilter.map(item =>
                        <div key={item} className={`filter-card-content-element ${seaterFilter.includes(item) && 'selected'}`} onClick={() => handleSeaterFilter(item)}>
                            {item}
                        </div>
                    )}
                </div>
            </div>

            <div className="filter-card">
                <div className="filter-card-type">
                    Fuel Type
                </div>
                <div className="filter-card-content">
                    {fuelFilter.map(item =>
                        <div key={item} className={`filter-card-content-element ${fuelTypeFilter.includes(item) && 'selected'}`} onClick={() => handleFuelTypeFilter(item)}>
                            {item}
                        </div>
                    )}
                </div>
            </div>
        </div>
    )
}

const mapStateToProps = (state) => {
    return {
        journeyData: state.journeyData
    };
}

export default connect(mapStateToProps, null)(LeftContainer);
