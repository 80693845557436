import React, { useState, useEffect } from 'react'

import AboutUs from './aboutUs'
import CarBanner from './carBanner'
import KeyFeatures from './keyFeatures'
import ServicesOffered from './servicesOffered'
import banner from '../../assets/car-rental.jpeg'
import ServicePageBanner from '../miscellaneous/servicePageBanner'
import Carousel from './carousel'
import CustomerReviews from './customerReviews'
import Footer from '../footer'

const Home = () => {
    const [isMweb, setIsMweb] = useState(false)
    const [noOfItems, setNoOfItems] = useState('desktop')

    useEffect(() => {
        handleResize();
        window.addEventListener('resize', handleResize);
    }, []);

    const handleResize = () => {

        if (window.screen.width < 541) {
            setNoOfItems(1);
            setIsMweb(true);
        } else if (window.screen.width < 1025) {
            setNoOfItems(3);
            setIsMweb(false);
        } else {
            setNoOfItems(4)
            setIsMweb(false);
        }
    };
    const bannerButtonsContent = [
        {
            id:1,
            text:"Rental Services",
            route:"/choose_car",
            tab:0
        },
        {
            id:2,
            text:"Tour Services",
            route:"/choose_car",
            tab:1
        },
        {
            id:1,
            text:"Taxi Services",
            route:"/choose_car",
            tab:2
        }
    ]
    return (
        <div>
            {/* <CarBanner /> */}
            <ServicePageBanner banner={banner} bannerButtonsContent={bannerButtonsContent}/>
            <AboutUs />
            <KeyFeatures isMweb={isMweb} />
            <ServicesOffered />
            {/* <Carousel noOfItems={noOfItems} /> */}
            <CustomerReviews noOfItems={noOfItems} />
            <Footer />
        </div>
    )
}

export default Home
