import React from "react";

export const Quotationtable = ({ quotations, handleDeleteQuotation }) => {
    return(

        // <div>
        //     Shiv
        // </div>

        <div>
        <table className="table table-bordered my-5">
            <thead>
                <tr>
                    <th scope="col">S No</th>
                    <th scope="col">Booking</th>
                    <th scope="col">Quantity</th>
                    <th scope="col">Rate</th>
                    <th scope="col">Amount</th>
                </tr>
            </thead>
            <tbody>
                {quotations.map((quotation, index) => {
                    return (
                        <tr>
                            <th scope="row">{quotation.sNo}</th>
                            <td>{quotation.booking}</td>
                            <td>{quotation.quantity}</td>
                            <td>{quotation.rate}</td>
                            <td>{quotation.amount}</td>
                            <td>
                                <i
                                    style={{ cursor: "pointer" }}
                                    className="fa fa-trash"
                                    onClick={() => handleDeleteQuotation(index)}
                                />
                            </td>
                        </tr>
                    )
                })}
                {/* Shiv */}
            </tbody>
        </table>
    </div>

    );
}